import React from 'react'

import styles from './styles.module.scss'

const Privacy = () => {
  return (
    <div className={styles.content}>
      <p className="mt-3">Effective Date: February 28, 2023</p>
      <p>
        This document governs the privacy notice of our website
        www.landsupplier.com. Our privacy notice tells you what personal data
        (PD) and non-personal data (NPD) we may collect from you, how we collect
        it, how we protect it, how we may share it, how you can access and
        change it, and how you can limit our sharing of it. Our privacy notice
        also explains certain legal rights that you have with respect to your
        personal data. Any capitalized terms not defined herein will have the
        same meaning as where they are defined elsewhere on our website.
      </p>
      <h2 className="h2-mb">Your Rights</h2>
      <p>
        When using our website and submitting personal data to us, you may have
        certain rights under the General Data Protection Regulation (GDPR) and
        other laws. Depending on the legal basis for processing your personal
        data, you may have some or all of the following rights:
      </p>
      <h3 className="h2-mb">The right to be informed</h3>
      <p>
        You have the right to be informed about the personal data we collect
        from you, and how we process it.
      </p>
      <h3 className="h2-mb">The right of access</h3>
      <p>
        You have the right to get confirmation that your personal data is being
        processed and have the ability to access your personal data.
      </p>
      <h3 className="h2-mb">The right to rectification</h3>
      <p>
        You have the right to have your personal data corrected if it is
        inaccurate or incomplete.
      </p>
      <h3 className="h2-mb">The right to erasure (right to be forgotten)</h3>
      <p>
        You have the right to request the removal or deletion of your personal
        data if there is no compelling reason for us to continue processing it.
      </p>
      <h3 className="h2-mb">The right to restrict processing</h3>
      <p>
        You have a right to ‘block’ or restrict the processing of your personal
        data. When your personal data is restricted, we are permitted to store
        your data, but not to process it further.
      </p>
      <h3 className="h2-mb">The right to data portability</h3>
      <p>
        You have the right to request and get your personal data that you
        provided to us and use it for your own purposes. We will provide your
        data to you within 30 days of your request. To request your personal
        data, please contact us using the information at the top of this privacy
        notice.
      </p>
      <h3 className="h2-mb">The right to object</h3>
      <p>
        You have the right to object to us processing your personal data for the
        following reasons:
      </p>
      <ul>
        <li>
          Processing was based on legitimate interests or the performance of a
          task in the public interest/exercise of official authority (including
          profiling);
        </li>
        <li>Direct marketing (including profiling); and</li>
        <li>
          Processing for purposes of scientific/historical research and
          statistics.
        </li>
        <li>Rights in relation to automated decision-making and profiling.</li>
        <li>Automated individual decision-making and profiling</li>
        <li>
          You will have the right not to be subject to a decision based solely
          on automated processing, including profiling, which produces legal
          effects concerning you or similarly significantly affects you.
        </li>
      </ul>
      <h3 className="h2-mb">Filing a complaint with authorities</h3>
      <p>
        You have the right to file a complaint with supervisory authorities if
        your information has not been processed in compliance with the General
        Data Protection Regulation. If the supervisory authorities fail to
        address your complaint properly, you may have the right to a judicial
        remedy.
      </p>
      <p>
        For details about your rights under the law, visit{' '}
        <a href="https://goo.gl/F41vAV">https://goo.gl/F41vAV</a>
      </p>
      <h2 className="h2-mb">Definitions</h2>
      <p>
        <span>‘Non-personal data’ (NPD)</span>is information that is in no way
        personally identifiable.
      </p>
      <p>
        <span>‘Personal data’ (PD)</span>means any information relating to an
        identified or identifiable natural person (‘data subject’); an
        identifiable natural person is one who can be identified, directly or
        indirectly, by reference to an identifier such as a name, an
        identification number, location data, an online identifier or to one or
        more factors specific to the physical, physiological, genetic, mental,
        economic, cultural or social identity of that natural person. PD is in
        many ways the same as Personally Identifiable Information (PII).
        However, PD is broader in scope and covers more data.
      </p>
      <p>
        A<span>“visitor”</span>is someone who merely browses our website. A
        <span>“member”</span>is someone who has registered with us to use or buy
        our services and products. The term<span>“user”</span>is a collective
        identifier that refers to either a visitor or a member.
      </p>
      <p>Topics Covered in Our Privacy notice</p>
      <ol>
        <li>Information We Collect</li>
        <li>Our Use of Cookies</li>
        <li>How Your Information Is Used</li>
        <li>Retaining and Destroying Your PD</li>
        <li>Updating Your PD</li>
        <li>Revoking Your Consent for Using Your PD</li>
        <li>Protecting the Privacy Rights of Third Parties</li>
        <li>Do Not Track Settings</li>
        <li>Links to Other Websites</li>
        <li>Protecting Children’s Privacy</li>
        <li>Our Email Policy</li>
        <li>Our Security Policy</li>
        <li>Use of Your Credit Card</li>
        <li>Transferring PD from the European Union</li>
        <li>Changes to Our Privacy Notice</li>
      </ol>
      <h2 className="h2-mb">Information We Collect</h2>
      <p>
        Generally, you control the amount and type of information that you
        provide to us when using our website.
      </p>
      <h3 className="h2-mb">
        Our Legal Basis for Collecting and Processing Personal Data
      </h3>
      <p>
        Our legal basis for collecting and processing your PD when you buy our
        products or services is based on and the necessity for the performance
        of a contract or to take steps to enter into a contract. Our legal basis
        for collecting and processing your PD when you sign up for our
        newsletter, subscriber list, and information about our products and
        services through our website opt-in forms is based on consent.
      </p>
      <h3 className="h2-mb">What Happens If You Don’t Give Us Your PD</h3>
      <p>
        If you do not provide us with enough PD, we may not be able to provide
        you with all our products and services. However, you can access and use
        some parts of our website without giving us your PD.
      </p>
      <h3 className="h2-mb">We Collect Your PD in the Following Ways:</h3>
      <h5>Automatic Information</h5>
      <p>
        We automatically receive information from your web browser or mobile
        device. This information includes the name of the website from which you
        entered our website, if any, as well as the name of the website you’ll
        visit when you leave our website. This information also includes the IP
        address of your computer/the proxy server you use to access the
        Internet, your Internet service provider’s name, your web browser type,
        the type of mobile device, your computer operating system, and data
        about your browsing activity when using our website. We use all this
        information to analyze trends among our users to help improve our
        website.
      </p>
      <h5>When Entering and Using Our Website</h5>
      <p>
        When you enter and use our website and agree to accept cookies, some of
        these cookies may contain your PD.
      </p>
      <h5>As a Visitor</h5>
      <p>
        As a visitor, if you submit an inquiry about a specific property, we
        collect your name, email address, and phone number.
      </p>
      <h5>Signing Up for Our Newsletter</h5>
      <p>
        If you sign up for our newsletter we collect your name and email
        address.
      </p>
      <h5>At User and Member Registration</h5>
      <p>
        When you register as a user or member, we collect some or all of the
        following information: email, first name, last name, phone number, data
        about your associate companies, postal address, credit card or other
        payment information.
      </p>
      <h5>Mobile Application</h5>
      <p>
        If you use our mobile application, you may have to provide PD to use it.
      </p>
      <h5>Collecting Your Location Information</h5>
      <p>
        When you use our mobile application, we may collect and process
        information about your actual physical location. Our mobile application
        will only track your physical location if you allow it to.
      </p>
      <h2 className="h2-mb">Our Use of Cookies</h2>
      <p>
        Our website uses cookies. A cookie is a small piece of data or a text
        file that is downloaded to your computer or mobile device when you
        access certain websites. Cookies may contain text that can be read by
        the web server that delivered the cookie to you. The text contained in
        the cookie generally consists of a sequence of letters and numbers that
        uniquely identifies your computer or mobile device; it may contain other
        information as well.
      </p>
      <p>
        <strong>
          By agreeing to accept our use of cookies, you are giving us, and third
          parties we partner with, permission to place, store, and access some
          or all the cookies described below on your computer.
        </strong>
      </p>
      <h5>Strictly Necessary Cookies</h5>
      <p>
        These cookies are necessary for proper functioning of the website, such
        as displaying content, logging in, validating your session, responding
        to your request for services, and other functions. Most web browsers can
        be set to disable the use of cookies. However, if you disable these
        cookies, you may not be able to access features on our website correctly
        or at all.
      </p>
      <h5>Performance Cookies</h5>
      <p>
        These cookies collect information about the use of the website, such as
        pages visited, traffic sources, users’ interests, content management,
        and other website measurements.
      </p>
      <h5>Functional Cookies</h5>
      <p>
        These cookies enable the website to remember a user’s choices – such as
        their language, user name, and other personal choices – while using the
        website. They can also be used to deliver services, such as letting a
        user make a blog post, listen to audio, or watch videos on the website.
      </p>
      <h5>Media Cookies</h5>
      <p>
        These cookies can be used to improve a website’s performance and provide
        special features and content. They can be placed by third parties who
        provide services to us or by our company.
      </p>
      <h5>Advertising or Targeting Cookies</h5>
      <p>
        These cookies are usually placed and used by advertising companies to
        develop a profile of your browsing interests and serve advertisements on
        other websites that are related to your interests. You will see less
        advertising if you disable these cookies.
      </p>
      <h5>Session Cookies</h5>
      <p>
        These cookies allow websites to link the actions of a user during a
        browser session. They may be used for a variety of purposes, such as
        remembering what a user has put in their shopping cart as they browse a
        website. Session cookies also permit users to be recognized as they
        navigate a website so that any item or page changes they make are
        remembered from page to page. Session cookies expire after a browser
        session; thus, they are not stored long term.
      </p>
      <h5>Persistent Cookies</h5>
      <p>
        These cookies are stored on a user’s device in between browser sessions,
        which allows the user’s preferences or actions across a site (or, in
        some cases, across different sites) to be remembered. Persistent cookies
        may be used for a variety of purposes, including remembering users’
        choices and preferences when using a website or to target advertising to
        them.
      </p>
      <h5>We may also use cookies for:</h5>
      <ul>
        <li>Identifying the areas of our website that you have visited</li>
        <li>Personalizing content that you see on our website</li>
        <li>Our website analytics</li>
        <li>Remarketing our products or services to you</li>
        <li>Remembering your preferences, settings, and login details</li>
        <li>Targeted advertising and serving ads relevant to your interests</li>
        <li>Affiliate marketing</li>
        <li>Allowing you to post comments</li>
        <li>Allowing you to share content with social networks</li>
      </ul>
      <p>
        Most web browsers can be set to disable the use of cookies. However, if
        you disable cookies, you may not be able to access features on our
        website correctly or at all.
      </p>
      <h5>Web Beacons</h5>
      <p>
        We may also use a technology called web beacons to collect general
        information about your use of our website and your use of special
        promotions or newsletters. The information we collect by web beacons
        allows us to statistically monitor the number of people who open our
        emails. Web beacons also help us to understand the behavior of our
        customers, members, and visitors.
      </p>
      <p></p>
      <h5>Google Analytics Privacy Notice</h5>
      <p>
        Our website uses Google Analytics to collect information about the use
        of our website. Google Analytics collects information from users such as
        age, gender, interests, demographics, how often they visit our website,
        what pages they visit, and what other websites they have used before
        coming to our website. We use the information we get from Google
        Analytics to analyze traffic, remarket our products and services to
        users, improve our marketing, advertising, and to improve our website.
        We have enabled Google Analytics advertising features such as
        remarketing with Google Analytics, Google Display Network Impression
        Reporting, and Google Analytics Demographics and Interest Reporting.
        Google Analytics collects only the IP address assigned to you on the
        date you visit our website, not your name or other identifying
        information, and we anonymize your IP data. We do not combine the
        information collected using Google Analytics with PD. Although Google
        Analytics plants a permanent cookie on your web browser to identify you
        as a unique user the next time you visit our website, the cookie cannot
        be used by anyone but Google. Google also uses specific identifiers to
        help collect information about the use of our website. For more
        information on how Google collects and processes your data, visit{' '}
        <a href="https://www.google.com/policies/privacy/partners/">
          https://www.google.com/policies/privacy/partners/
        </a>
      </p>
      <p>
        You can prevent Google Analytics from using your information by opting
        out at this link:{' '}
        <a href="https://tools.google.com/dlpage/gaoptout">
          https://tools.google.com/dlpage/gaoptout
        </a>
      </p>
      <h5>Google Remarketing</h5>
      <p>Why am I seeing ads by Google for products I’ve viewed?</p>
      <p>
        Our website uses a remarketing advertising service. Our remarketing
        service is provided by Google and other companies that show our ads on
        websites across the Internet. With remarketing you may see ads for our
        products you have previously looked at. As an example, suppose you visit
        a website that sells computers, but you do not buy a computer on your
        first visit to that website. The website’s owner might like to encourage
        you to revisit his/her site and buy a computer by showing you his/her
        ads again on other websites that you visit. We use remarketing for
        similar purposes. For this to happen, Google will read a cookie that is
        already in your browser, or they place a cookie in your browser when you
        visit our site or other sites using remarketing. You can opt out of
        Google’s use of cookies and remarketing at this link:{' '}
        <a
          href="https://support.google.com/ads/answer/2662922?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          https://support.google.com/ads/answer/2662922?hl=en
        </a>{' '}
        or you can opt out using the Network Advertising Initiative opt out page
        at:{' '}
        <a
          href="http://optout.networkadvertising.org/#!/"
          target="_blank"
          rel="noreferrer"
        >
          http://optout.networkadvertising.org/#!/
        </a>
      </p>
      <h5>Facebook Remarketing</h5>
      <p>
        Third parties, including Facebook, may use cookies, web beacons, and
        other storage technologies to collect or receive information from our
        website and elsewhere on the internet, and use that information to
        provide measurement services and target ads. With Facebook remarketing
        you may see our ads on Facebook after you have visited our site. For
        this to happen, Facebook uses a Custom Audience Pixel that is activated
        when a visitor lands on a webpage and a unique “cookie” is placed in
        their browser. Facebook lookalike audience targeting allows us to show
        ads on Facebook to people who are similar to those who have already
        visited or made a purchase from our website. To opt out of Facebook’s
        collection and use of information for ad targeting visit:{' '}
        <a href="https://www.facebook.com/help/568137493302217">
          https://www.facebook.com/help/568137493302217
        </a>
      </p>
      <h2 className="h2-mb">How Your Information Is Used</h2>
      <p>We use the information we receive from you to:</p>
      <ul>
        <li>
          Provide our products and services you have requested or purchased from
          us
        </li>
        <li>Personalize and customize our content</li>
        <li>Make improvements to our website</li>
        <li>Contact you with updates to our website</li>
        <li>Resolve problems and disputes</li>
        <li>
          Contact you with marketing and advertising that we believe may be of
          interest to you
        </li>
      </ul>
      <h3 className="h2-mb">Communications and Emails</h3>
      <p>
        When we communicate with you about our website, we will use the email
        address you provided when you registered as a member or user. We may
        also send you emails with promotional information about our website or
        offers from us or our affiliates unless you have opted out of receiving
        such information. You can change your contact preferences at any time
        through your account or by sending us an email with your request to:
        support@landsupplier.com
      </p>
      <h3 className="h2-mb">
        Sharing Information With Affiliates and Other Third Parties
      </h3>
      <p>
        We do not sell or rent your PD to third parties for marketing purposes.
        However, for data aggregation purposes we may use your NPD, which might
        be sold to other parties at our discretion. Any such data aggregation
        would not contain any of your PD. We may provide your PD to third-party
        service providers we hire to provide services to us. These third-party
        service providers may include but are not limited to: payment
        processors, web analytics companies, customer relationship software
        providers, data management services, help desk providers, accountants,
        law firms, auditors, shopping cart and email service providers.
      </p>
      <h3 className="h2-mb">Legally Required Releases of Information</h3>
      <p>
        We may be legally required to disclose your PD if such disclosure is (a)
        required by subpoena, law, or other legal process; (b) necessary to
        assist law enforcement officials or government enforcement agencies; (c)
        necessary to investigate violations of or otherwise enforce our Legal
        Terms; (d) necessary to protect us from legal action or claims from
        third parties, including you and/or other users or members; or (e)
        necessary to protect the legal rights, personal/real property, or
        personal safety of our company, users, employees, and affiliates.
      </p>
      <h3 className="h2-mb">Disclosures to Successors</h3>
      <p>
        If our business is sold or merges in whole or in part with another
        business that would become responsible for providing the website to you,
        we retain the right to transfer your PD to the new business. The new
        business would retain the right to use your PD according to the terms of
        this privacy notice as well as to any changes to this privacy notice as
        instituted by the new business. We also retain the right to transfer
        your PD if our company files for bankruptcy and some or all of our
        assets are sold to another individual or business.
      </p>
      <h3 className="h2-mb">Community Discussion Boards</h3>
      <p>
        Our website may offer the ability for users to communicate with each
        other through online community discussion boards or other mechanisms. We
        do not filter or monitor what is posted on such discussion boards. If
        you choose to post on these discussion boards, you should use care when
        exposing any PD, as such information is not protected by our privacy
        notice nor are we liable if you choose to disclose your PD through such
        postings. Also, PD you post on our website for publication may be
        available worldwide by means of the Internet. We cannot prevent the use
        or misuse of such information by others.
      </p>
      <h2 className="h2-mb">Retaining and Destroying Your PD</h2>
      <p>
        We retain information that we collect from you (including your PD) only
        for as long as we need it for legal, business, or tax purposes. Your
        information may be retained in electronic form, paper form, or a
        combination of both. When your information is no longer needed, we will
        destroy, delete, or erase it.
      </p>
      <h2 className="h2-mb">Updating Your PD</h2>
      <p>
        You can update your PD using services found on our website. If no such
        services exist, you can contact us using the contact information found
        at the top of this notice and we will help you. However, we may keep
        your PD as needed to enforce our agreements and to comply with any legal
        obligations.
      </p>
      <h2 className="h2-mb">Revoking Your Consent for Using Your PD</h2>
      <p>
        You have the right to revoke your consent for us to use your PD at any
        time. Such an optout will not affect disclosures otherwise permitted by
        law including but not limited to: (i) disclosures to affiliates and
        business partners, (ii) disclosures to third-party service providers
        that provide certain services for our business, such as credit card
        processing, computer system services, shipping, data management
        services, (iii) disclosures to third parties as necessary to fulfill
        your requests, (iv) disclosures to governmental agencies or law
        enforcement departments, or as otherwise required to be made under
        applicable law, (v) previously completed disclosures to third parties,
        or (vi) disclosures to third parties in connection with subsequent
        contests or promotions you may choose to enter, or third-party offers
        you may choose to accept. If you want to revoke your consent for us to
        use your PD, send us an email with your request to:
        sales@landsupplier.com
      </p>
      <h2 className="h2-mb">Protecting the Privacy Rights of Third Parties</h2>
      <p>
        If any postings you make on our website contain information about third
        parties, you must make sure you have permission to include that
        information in your posting. While we are not legally liable for the
        actions of our users, we will remove any postings about which we are
        notified, if such postings violate the privacy rights of others.
      </p>
      <h2 className="h2-mb">Do Not Track Settings</h2>
      <p>
        Some web browsers have settings that enable you to request that our
        website not track your movement within our website. Our website does not
        obey such settings when transmitted to and detected by our website. You
        can turn off tracking features and other security settings in your
        browser by referring to your browser’s user manual.
      </p>
      Links to Other Websites
      <p>
        Our website may contain links to other websites. These websites are not
        under our control and are not subject to our privacy notice. These
        websites will likely have their own privacy notices. We have no
        responsibility for these websites and we provide links to these websites
        solely for your convenience. You acknowledge that your use of and access
        to these websites are solely at your risk. It is your responsibility to
        check the privacy notices of these websites to see how they treat your
        PD.
      </p>
      Protecting Children’s Privacy
      <p>
        Even though our website is not designed for use by anyone under the age
        of 18, we realize that a child under the age of 16 may attempt to access
        our website. We do not knowingly collect PD from children under the age
        of 16. If you are a parent or guardian and believe that your child is
        using our website, please contact us. Before we remove any information,
        we may ask for proof of identification to prevent malicious removal of
        account information. If we discover that a child is accessing our
        website, we will delete his/her information within a reasonable period
        of time. You acknowledge that we do not verify the age of our users nor
        do we have any liability to do so.
      </p>
      Our Email Policy
      <p>
        You can always opt out of receiving further email correspondence from us
        or our affiliates. We will not sell, rent, or trade your email address
        to any unaffiliated third party without your permission except in the
        sale or transfer of our business, or if our company files for
        bankruptcy.
      </p>
      Our Security Policy
      <p>
        We have built our website using industry-standard security measures and
        authentication tools to protect the security of your PD. We and the
        third parties who provide services for us, also maintain technical and
        physical safeguards to protect your PD. When we collect your credit card
        information through our website, we will encrypt it before it travels
        over the Internet using industry-standard technology for conducting
        secure online transactions. Unfortunately, we cannot guarantee against
        the loss or misuse of your PD or secure data transmission over the
        Internet because of its nature.
      </p>
      <p>
        We strongly urge you to protect any password you may have for our
        website and to not share it with anyone. You should always log out of
        our website when you finish using it, especially if you are sharing or
        using a computer in a public place.
      </p>
      Use of Your Credit Card
      <p>
        You may have to provide a credit card to buy products and services from
        our website. We use third-party billing services and have no control
        over these services. We use our commercially reasonable efforts to make
        sure your credit card number is kept strictly confidential by using only
        third-party billing services that use industry-standard encryption
        technology to protect your credit card number from unauthorized use.
        However, you understand and agree that we are in no way responsible for
        any misuse of your credit card number.
      </p>
      Transferring PD From the European Union
      <p>
        (PD) that we collect from you may be stored, processed, and transferred
        between any of the countries in which we operate, specifically the
        United States. The European Union has not found the United States and
        some other countries to have an adequate level of protection of PD under
        Article 45 of the GDPR. Our company relies on derogations for specific
        situations as defined in Article 49 of the GDPR. For European Union
        customers and users, with your consent, your PD may be transferred
        outside the European Union to the United States and or other countries.
        We will use your PD to provide the goods, services, and/or information
        you request from us to perform a contract with you or to satisfy a
        legitimate interest of our company in a manner that does not outweigh
        your freedoms and rights. Wherever we transfer, process or store your
        PD, we will take reasonable steps to protect it. We will use the
        information we collect from you in accordance with our privacy notice.
        By using our website, services, or products, you agree to the transfers
        of your PD described within this section.
      </p>
      Changes to Our Privacy notice
      <p>
        We reserve the right to change this privacy notice at any time. If our
        company decides to change this privacy notice, we will post those
        changes on our website so that our users and customers are always aware
        of what information we collect, use, and disclose. If at any time we
        decide to disclose or use your PD in a method different from that
        specified at the time it was collected, we will provide advance notice
        by email (sent to the email address on file in your account). Otherwise
        we will use and disclose our users’ and customers’ PD in agreement with
        the privacy notice in effect when the information was collected. In all
        cases, your continued use of our website, services, and products after
        any change to this privacy notice will constitute your acceptance of
        such change.
      </p>
      <h2 className="h2-mb">Questions About Our Privacy notice</h2>
      <p>
        If you have any questions about our privacy notice, please contact us
        using the information at the top of this privacy notice.
      </p>
      <h2 className="h2-mb">How To Contact Us</h2>
      <p>
        Should you have other questions or concerns about these privacy
        policies, please contact us .
      </p>
      <p>
        Copyright © This privacy notice is protected under United States and
        foreign copyrights. The copying, redistribution, use or publication by
        you, is strictly prohibited.
      </p>
    </div>
  )
}

export default Privacy
