import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { history } from '../../../../index'
import localStorageHelper from '../../../../utils/local-storage.util'
import actions from '../../../../redux/modals/actions'

import styles from '../sidebar.module.scss'

const SidebarFooterMenu = ({ user, visible, setVisibility }) => {
  const dispatch = useDispatch()
  const ref = React.useRef(null)

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        visible &&
        ref &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setVisibility(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line
  }, [visible, ref])

  return (
    <div
      className={`${styles.footerMenu} ${visible ? styles.footerMenuOpen : ''}`}
      ref={ref}
    >
      <div className={styles.footerMenuWrapper}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <div className={styles.logoWrapper}>
              {user.avatar ? (
                <img src={user.avatar} alt={user.name} />
              ) : (
                <span>{user.name.charAt(0)}</span>
              )}
            </div>
          </div>
          <div className={styles.companyName}>
            <h3>{user.name}</h3>
            <p>{user.email}</p>
          </div>
        </div>
        <div className={styles.menu}>
          <ul>
            {user && user.is_admin ? (
              <>
                <li>
                  <Link to="/admin/users" onClick={() => setVisibility(false)}>
                    Users
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/analytics"
                    onClick={() => setVisibility(false)}
                  >
                    Analytics
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/properties" onClick={() => setVisibility(false)}>
                    Properties
                  </Link>
                </li>
                <li>
                  <Link to="/analytics" onClick={() => setVisibility(false)}>
                    Analytics
                  </Link>
                </li>
                <li>
                  <Link to="/settings" onClick={() => setVisibility(false)}>
                    Settings
                  </Link>
                </li>
                <li>
                  <Link
                    to="/settings/subscription"
                    onClick={() => setVisibility(false)}
                  >
                    Subscription
                  </Link>
                </li>
                <li>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault()
                      dispatch({
                        type: actions.LEGAL_MODAL,
                        payload: {
                          legal: {
                            visible: true,
                            type: 'terms',
                          },
                        },
                      })
                    }}
                  >
                    Terms
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault()
                      dispatch({
                        type: actions.LEGAL_MODAL,
                        payload: {
                          legal: {
                            visible: true,
                            type: 'privacy',
                          },
                        },
                      })
                    }}
                  >
                    Privacy
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault()
                      dispatch({
                        type: actions.LEGAL_MODAL,
                        payload: {
                          legal: {
                            visible: true,
                            type: 'noncompete',
                          },
                        },
                      })
                    }}
                  >
                    Noncompete Agreement
                  </a>
                </li>
              </>
            )}
            {user.isAdmin && (
              <li>
                <Link to="/admin" onClick={() => setVisibility(false)}>
                  Admin Panel
                </Link>
              </li>
            )}
            <li className={styles.divider} />
            <li>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  localStorageHelper.remove('apiToken')
                  history.push('/secure/login')
                }}
              >
                Sign Out
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SidebarFooterMenu
