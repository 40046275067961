import { combineReducers } from '@reduxjs/toolkit'

import users from './users/reducers'
import modals from './modals/reducers'

const createRootReducer = (routerReducer) =>
  combineReducers({
    router: routerReducer,
    users,
    modals,
  })

export default createRootReducer
