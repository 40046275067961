import React from 'react'

import styles from './auth.module.scss'

const AuthLayout = ({ children }) => {
  return (
    <div className={styles.authLayout}>
      <div className={styles.layoutWrapper}>
        <h1>
          Welcome back to <span>LandSupplier</span>
        </h1>
        <div className={styles.layoutForm}>{children}</div>
      </div>
    </div>
  )
}

export default AuthLayout
