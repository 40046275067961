import React from 'react'
import { Modal } from 'antd'

import PageTitle from '../page-title'

const VideoModal = ({ visible, onClose, title = '', videoLink = null }) => {
  if (!videoLink) {
    return null
  }

  return (
    <Modal
      open={visible}
      closable={false}
      onCancel={onClose}
      title={null}
      centered
      width={1200}
      destroyOnClose
      footer={false}
      className="create-campaign-modal"
    >
      <PageTitle title={title} heading="h2" />
      <div>
        <iframe
          width="100%"
          height="600"
          src={videoLink}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Modal>
  )
}

export default VideoModal
