import React, { useState } from 'react'

import ContactModal from '../ContactModal'

import styles from '../../styles.module.scss'

const social = [
  {
    key: 1,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.9453 6.48603C8.9453 6.93963 8.9453 8.96427 8.9453 8.96427H7V11.9947H8.9453V21H12.9414V11.9949H15.6229C15.6229 11.9949 15.8741 10.5419 15.9958 8.9531C15.6468 8.9531 12.9565 8.9531 12.9565 8.9531C12.9565 8.9531 12.9565 7.1901 12.9565 6.88109C12.9565 6.5714 13.3922 6.15483 13.8228 6.15483C14.2526 6.15483 15.1598 6.15483 16 6.15483C16 5.74223 16 4.31663 16 3C14.8783 3 13.6022 3 13.0397 3C8.84652 2.99979 8.9453 6.03321 8.9453 6.48603Z"
          fill="#3e914a"
        />
      </svg>
    ),
    link: 'https://www.facebook.com/LandSupplier',
  },
  // {
  //   key: 2,
  //   icon: (
  //     <svg
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M7.02911 21H3.29732V9.64997H7.02911V21ZM5.16121 8.10172C3.9679 8.10172 3 7.16822 3 6.04118C3 5.49983 3.2277 4.98065 3.633 4.59785C4.03831 4.21505 4.58802 4 5.16121 4C5.73439 4 6.2841 4.21505 6.68941 4.59785C7.09471 4.98065 7.32241 5.49983 7.32241 6.04118C7.32241 7.16822 6.35411 8.10172 5.16121 8.10172ZM20.996 21H17.2722V15.4749C17.2722 14.1581 17.2441 12.4695 15.332 12.4695C13.3918 12.4695 13.0945 13.9001 13.0945 15.38V21H9.3667V9.64997H12.9458V11.1982H12.998C13.4963 10.3065 14.7133 9.36537 16.5289 9.36537C20.3057 9.36537 21 11.7143 21 14.7653V21H20.996Z"
  //         fill="#3e914a"
  //       />
  //     </svg>
  //   ),
  //   link: '/',
  // },
  // {
  //   key: 3,
  //   icon: (
  //     <svg
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M21.6544 7.25207C21.4095 6.18386 20.5396 5.39577 19.4922 5.27821C17.0121 5.00017 14.5019 4.99872 12.0032 5.00017C9.5041 4.99872 6.99344 5.00017 4.51334 5.27821C3.46663 5.39577 2.59726 6.18386 2.35241 7.25207C2.00392 8.7733 2 10.4339 2 12.0001C2 13.5663 2 15.2267 2.34849 16.7477C2.59293 17.8157 3.4625 18.6038 4.50983 18.7216C6.98973 18.9998 9.50018 19.0013 11.9993 18.9998C14.4988 19.0013 17.0086 18.9998 19.4883 18.7216C20.535 18.604 21.4052 17.8159 21.6501 16.7477C21.9988 15.2265 22 13.5661 22 12.0001C22 10.4339 22.0029 8.7733 21.6544 7.25207ZM9.41905 15.1176C9.41905 12.8958 9.41905 10.6972 9.41905 8.47515C11.5387 9.58586 13.6443 10.6891 15.7773 11.8067C13.6509 12.9143 11.5443 14.0109 9.41905 15.1176Z"
  //         fill="#3e914a"
  //       />
  //     </svg>
  //   ),
  //   link: 'https://landsupplier.com/#',
  // },
  {
    key: 4,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.96725 3H16.0325C18.7717 3 21.0001 5.22841 21 7.96736V16.0326C21 18.7716 18.7717 21 16.0325 21H7.96725C5.2283 21 3 18.7717 3 16.0326V7.96736C3 5.22841 5.2283 3 7.96725 3ZM15.6 12C15.6 13.9883 13.9882 15.6 12 15.6C10.0118 15.6 8.4 13.9883 8.4 12C8.4 10.0117 10.0118 8.4 12 8.4C13.9882 8.4 15.6 10.0117 15.6 12ZM16.95 8.4C17.6956 8.4 18.3 7.79559 18.3 7.05C18.3 6.30441 17.6956 5.7 16.95 5.7C16.2044 5.7 15.6 6.30441 15.6 7.05C15.6 7.79559 16.2044 8.4 16.95 8.4Z"
          fill="#3e914a"
        />
      </svg>
    ),
    link: 'https://www.instagram.com/landsupplier/',
  },
  {
    key: 5,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 5.89415C21.2563 6.21538 20.4637 6.42831 19.6375 6.53169C20.4875 6.032 21.1363 5.24677 21.4412 4.30031C20.6488 4.76554 19.7738 5.09415 18.8412 5.27754C18.0887 4.48862 17.0162 4 15.8462 4C13.5763 4 11.7487 5.81415 11.7487 8.03815C11.7487 8.35815 11.7762 8.66585 11.8438 8.95877C8.435 8.79508 5.41875 7.18646 3.3925 4.736C3.03875 5.34031 2.83125 6.032 2.83125 6.77662C2.83125 8.17477 3.5625 9.41415 4.6525 10.1317C3.99375 10.1194 3.3475 9.93108 2.8 9.63446C2.8 9.64677 2.8 9.66277 2.8 9.67877C2.8 11.6406 4.22125 13.2702 6.085 13.6455C5.75125 13.7354 5.3875 13.7785 5.01 13.7785C4.7475 13.7785 4.4825 13.7637 4.23375 13.7095C4.765 15.3083 6.2725 16.4837 8.065 16.5218C6.67 17.5963 4.89875 18.2437 2.98125 18.2437C2.645 18.2437 2.3225 18.2289 2 18.1883C3.81625 19.3415 5.96875 20 8.29 20C15.835 20 19.96 13.8462 19.96 8.512C19.96 8.33354 19.9538 8.16123 19.945 7.99015C20.7588 7.42154 21.4425 6.71138 22 5.89415Z"
          fill="#3e914a"
        />
      </svg>
    ),
    link: 'https://twitter.com/LandSupplier',
  },
  // {
  //   key: 6,
  //   icon: (
  //     <svg
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <g clipPath="url(#clip0_2546_18180)">
  //         <path
  //           d="M20.3171 4.36976C18.7874 3.6679 17.1471 3.15078 15.4319 2.85458C15.4007 2.84886 15.3695 2.86315 15.3534 2.8917C15.1424 3.26693 14.9088 3.7564 14.7451 4.14115C12.9005 3.86497 11.0652 3.86497 9.25836 4.14115C9.09468 3.74787 8.85252 3.26693 8.6406 2.8917C8.62452 2.86409 8.5933 2.84979 8.56208 2.85458C6.84796 3.14979 5.20761 3.66692 3.67696 4.36976C3.66369 4.37548 3.65235 4.385 3.6448 4.39737C0.533426 9.04573 -0.318949 13.5799 0.0991759 18.0577C0.101051 18.0796 0.113379 18.1006 0.130395 18.1139C2.18319 19.6215 4.17172 20.5367 6.12327 21.1433C6.15449 21.1528 6.18758 21.1414 6.20746 21.1157C6.66908 20.4853 7.0806 19.8206 7.43343 19.1215C7.45424 19.0806 7.43436 19.032 7.3918 19.0158C6.73907 18.7682 6.11755 18.4663 5.51966 18.1235C5.47236 18.0959 5.46857 18.0282 5.51211 17.9959C5.63793 17.9016 5.76379 17.8035 5.88393 17.7044C5.90568 17.6863 5.93596 17.6825 5.9615 17.6939C9.88925 19.4872 14.1415 19.4872 18.0229 17.6939C18.0485 17.6816 18.0787 17.6854 18.1014 17.7035C18.2216 17.8025 18.3474 17.9015 18.4742 17.9958C18.5177 18.0282 18.5148 18.0958 18.4676 18.1235C17.8697 18.4729 17.2482 18.7682 16.5945 19.0148C16.5519 19.031 16.533 19.0805 16.5538 19.1215C16.9143 19.8195 17.3258 20.4843 17.7789 21.1147C17.7978 21.1414 17.8318 21.1528 17.8631 21.1433C19.8241 20.5367 21.8126 19.6214 23.8654 18.1139C23.8834 18.1006 23.8948 18.0806 23.8966 18.0587C24.3971 12.8817 23.0585 8.38479 20.3482 4.39831C20.3416 4.385 20.3303 4.37548 20.3171 4.36976ZM8.02007 15.3312C6.83755 15.3312 5.86316 14.2455 5.86316 12.9122C5.86316 11.5789 6.81861 10.4933 8.02007 10.4933C9.23089 10.4933 10.1959 11.5885 10.1769 12.9122C10.1769 14.2455 9.22143 15.3312 8.02007 15.3312ZM15.9948 15.3312C14.8123 15.3312 13.8379 14.2455 13.8379 12.9122C13.8379 11.5789 14.7934 10.4933 15.9948 10.4933C17.2056 10.4933 18.1706 11.5885 18.1517 12.9122C18.1517 14.2455 17.2057 15.3312 15.9948 15.3312Z"
  //           fill="#3e914a"
  //         />
  //       </g>
  //       <defs>
  //         <clipPath id="clip0_2546_18180">
  //           <rect width="24" height="24" fill="white" />
  //         </clipPath>
  //       </defs>
  //     </svg>
  //   ),
  //   link: '/',
  // },
]

const ContactUs = () => {
  const [contactModal, setContactModal] = useState(false)

  return (
    <>
      <div className="animated animate__animated animate__fadeIn overflow-hidden">
        <div
          className={`${styles.card1Inverted} ${styles.card1InvertedColorized}`}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="d-flex justify-content-start align-items-center cursor-pointer user-select-none mb-3"
            onClick={() => {
              setContactModal(true)
            }}
          >
            <div className={styles.cardIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#3e914a"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
            </div>
            <div>
              <h3 className={styles.cardTitle}>727-557-6149</h3>
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="d-flex justify-content-start align-items-center cursor-pointer user-select-none"
            onClick={() => {
              setContactModal(true)
            }}
          >
            <div className={styles.cardIcon}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                  stroke="#3e914a"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 6L12 13L2 6"
                  stroke="#3e914a"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div>
              <h3 className={styles.cardTitle}>robert@landsupplier.com</h3>
            </div>
          </div>
        </div>
        <h1 className="mt-5">Social Media Networks</h1>
        <div className={`row ${styles.socialLinks}`}>
          {social.map(({ key, icon, link }, index) => {
            return (
              <>
                {index === 3 && <div className="d-none d-lg-block col-lg-3" />}
                <a
                  href={link}
                  className="col-6 col-md-4 col-lg-3 mb-4"
                  key={key}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  <div className={styles.socialLink}>{icon}</div>
                </a>
              </>
            )
          })}
        </div>
      </div>
      <ContactModal
        setVisibility={() => setContactModal(false)}
        visible={contactModal}
      />
    </>
  )
}

export default ContactUs
