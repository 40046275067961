import React from 'react'
import { useSelector } from 'react-redux'

import styles from './main.module.scss'
import Sidebar from '../components/layouts/sidebar'
import Header from '../components/layouts/header'
import RightSidebar from '../components/layouts/right-sidebar'
import SubscriptionsListModal from '../components/ui/subscription/SubscriptionsListModal'
import TermsModal from '../components/ui/terms-modal'

const MainLayout = ({ children }) => {
  const users = useSelector((state) => state.users)
  const modals = useSelector((state) => state.modals)
  const { rightSidebar } = modals
  const [leftSidebarState, setLeftSidebarState] = React.useState(false)
  const [rightSidebarState, setRightSidebarState] = React.useState(false)

  return (
    <>
      <div
        className={`${styles.mainLayout} ${
          leftSidebarState ? styles.mainLayoutFull : styles.mainLayoutCompact
        } ${rightSidebar ? styles.mainLayoutNoRightSidebar : ''} ${
          users.user.is_admin ? styles.adminLayout : ''
        }`}
      >
        <Sidebar
          user={users.user}
          setSidebarState={setLeftSidebarState}
          state={leftSidebarState}
        />
        <div className={styles.content}>
          <Header
            user={users.user}
            leftSidebarState={leftSidebarState}
            setLeftSidebarState={setLeftSidebarState}
            rightSidebar={rightSidebar}
          />
          <main>{children}</main>
        </div>
        {!users.user.is_admin && (
          <RightSidebar
            user={users.user}
            rightSidebar={rightSidebar}
            rightSidebarState={rightSidebarState}
            setRightSidebarState={setRightSidebarState}
          />
        )}
      </div>
      <SubscriptionsListModal />
      <TermsModal />
    </>
  )
}

export default MainLayout
