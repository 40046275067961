import React from 'react'

import HowItWorks from '../../../../ui/properties/modals/boost-modal/partials/content/HowItWorks'

import styles from './video-library.module.scss'

const VideoLibrary = () => {
  const videos = [
    // {
    //   video: 'https://www.youtube.com/embed/7gdALxuIeW8',
    //   title: 'Introduction',
    // },
    // {
    //   video: 'https://www.youtube.com/embed/w7a6-5nDxfk',
    //   title: 'How do I get started?',
    // },
    // {
    //   video: 'https://www.youtube.com/embed/09IDbaXPmCQ',
    //   title: 'Profit Share & Business Management',
    // },
    // {
    //   video: 'https://www.youtube.com/embed/VwCDLA6DYj0',
    //   title: 'Marketing & Targeted Leads',
    // },
    // {
    //   video: 'https://www.youtube.com/embed/9NNn7n6AEU8',
    //   title: 'Email Marketing & Newsletters Automation',
    // },
    // {
    //   video: 'https://www.youtube.com/embed/GW4l-bI6zg8',
    //   title: 'Subscription - Starting Plan',
    // },
    // {
    //   video: 'https://www.youtube.com/embed/xbwYygWEoyo',
    //   title: 'Subscription - Intermediate Plan',
    // },
    // {
    //   video: 'https://www.youtube.com/embed/15xv5FbTyFg',
    //   title: 'Subscription - Professional Plan',
    // },
    // {
    //   video: 'https://www.youtube.com/embed/S73-_Pxnkeg',
    //   title: 'Subscription - Expert Plan',
    // },
  ]

  return (
    <div className="row">
      <div className="col-12">
        {videos.map((video) => (
          <HowItWorks
            extraClass={styles.videoLibrary}
            key={video.video}
            blockTitle={video.title}
            link={video.video}
            hideSubTitle
          />
        ))}
      </div>
    </div>
  )
}

export default VideoLibrary
