import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import ContactModal from '../ContactModal'
import MainTabStats from './partials/MainTabStats'

// import actions from '../../../../../redux/modals/actions'

import styles from '../../styles.module.scss'
import HowItWorksTextModal from '../../../../ui/how-it-works/HowItWorksTextModal'

const MainTab = () => {
  // const dispatch = useDispatch()
  const users = useSelector((state) => state.users)
  const { user, subscription } = users
  const [contactModal, setContactModal] = useState(false)
  const [howItWorksModal, setHowItWorksModal] = useState(false)

  // console.log(user)

  return (
    <>
      <div className="container-fluid animated animate__animated animate__fadeIn">
        <div className={styles.card1}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="user-select-none"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setHowItWorksModal(true)
            }}
          >
            <h3 className={styles.cardTitle}>How It Works</h3>
            <p className={styles.cardText}>Why are we unique and different?</p>
          </div>
        </div>
        <div className={styles.card1}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="user-select-none"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              window.open('https://webinar.landsupplier.com/')
            }}
          >
            <h3 className={styles.cardTitle}>Join Webinar</h3>
            <p className={styles.cardText}>Click here to join webinar</p>
          </div>
        </div>
        <div className={styles.card1}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="user-select-none"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setContactModal(true)
            }}
          >
            <h3 className={styles.cardTitle}>Need Help?</h3>
            <p className={styles.cardText}>
              Regarding the details and any questions, please get in touch with
              Robert directly.
            </p>
          </div>
        </div>
        {subscription && user && user.get_website && user.get_website.id ? (
          <MainTabStats />
        ) : (
          <MainTabStats withData={true} />
        )}
      </div>
      <ContactModal
        visible={contactModal}
        setVisibility={() => setContactModal(false)}
      />
      <HowItWorksTextModal
        visible={howItWorksModal}
        setVisibility={() => setHowItWorksModal(false)}
      />
    </>
  )
}

export default MainTab
