import React, { createContext, useEffect, useState } from 'react'

const ThemeContext = createContext({})

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light')

  useEffect(() => {
    const getTheme = localStorage.getItem('appTheme')
    if (getTheme) {
      setTheme(getTheme)
    } else {
      setTheme('light')
    }
  }, [])

  useEffect(() => {
    if (theme === 'light') {
      localStorage.setItem('appTheme', 'light')
      document
        .getElementsByTagName('html')[0]
        .setAttribute('data-theme', 'light')
    } else {
      localStorage.setItem('appTheme', 'dark')
      document
        .getElementsByTagName('html')[0]
        .setAttribute('data-theme', 'dark')
    }
  }, [theme])

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light')
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }
