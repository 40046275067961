import actions from './actions'

const initialState = {
  subscriptionModal: false,
  rightSidebar: false,
  rightSidebarActiveTab: 1,
  legal: {
    visible: false,
    type: '',
  },
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
