import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import styles from './notifications.module.scss'
import axiosRequest from '../../../../../utils/axios-request.util'
import axiosErrorUtil from '../../../../../utils/axios-error.util'
import moment from 'moment'

const NotificationsRightSidebar = () => {
  const locations = useLocation()
  const { pathname } = locations
  const [data, setData] = useState([])

  const getData = () => {
    axiosRequest('api/v1/notifications', 'get', null, true)
      .then((res) => {
        setData(res.data.notifications)
      })
      .catch((e) => axiosErrorUtil(e))
  }

  useEffect(() => {
    getData()
  }, [pathname])

  const clearAll = () => {
    axiosRequest('api/v1/notifications', 'delete', null, true)
      .then(() => {
        getData()
      })
      .catch((e) => axiosErrorUtil(e))
  }

  const removeNotification = (id) => {
    axiosRequest(`api/v1/notifications/${id}`, 'delete', null, true)
      .then(() => {
        getData()
      })
      .catch((e) => axiosErrorUtil(e))
  }

  return (
    <div className="animated animate__animated animate__fadeIn">
      <div className={styles.notifications}>
        <div className={styles.header}>
          <h2 className={styles.title}>Notifications</h2>
          <div className={styles.close}>
            <button type="button" onClick={() => clearAll()}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 6L6 14"
                  stroke="#3e914a"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 6L14 14"
                  stroke="#3e914a"
                  opacity="0.7"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Clear All</span>
            </button>
          </div>
        </div>
        <div className={styles.items}>
          {data.map((notification) => (
            <div key={notification.id} className={styles.item}>
              <div className={styles.head}>
                <h3 className={styles.category}>{notification.type}</h3>
                <div className="d-flex justify-content-end align-items-center">
                  <div className={styles.time}>
                    {moment
                      .duration(moment().diff(moment(notification.created_at)))
                      .humanize()}
                  </div>
                  <div className="ms-3">
                    <button onClick={() => removeNotification(notification.id)}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 6L6 14"
                          stroke="#f1416c"
                          opacity="0.5"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 6L14 14"
                          stroke="#f1416c"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: notification.notification }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NotificationsRightSidebar
