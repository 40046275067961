import React from 'react'

import styles from './styles.module.scss'

const NoncompeteAgreement = () => {
  return (
    <div className={styles.content}>
      <p className="mt-3">
        In this Agreement, the party who is requesting the non-competition from
        the other party shall be referred to as "Land Supplier", and the party
        who is agreeing not to compete shall be referred to as "Non-Competing
        Party".
      </p>
      <p>
        Agreement is made for non-compete with other companies and solisitation
        of employees and Land Supplier data, leads, clients, websites, phone
        numbers, email and all other critical components of business.
      </p>
      <p>
        <strong>1. NONCOMPETE COVENANT.</strong> During period from effective
        date of this Agreement until Non-Competing party is no longer working
        with Land Supplier, Non-Competing Party will not directly or indirectly
        engage in any business that competes with Land Supplier. This covenant
        shall apply to the geographical area that includes the whole United
        States of America Country.
      </p>
      <p>
        <strong>2. NON-SOLICITATION COVENANT.</strong> For a period of One Year
        after the effective date of this Agreement, Non-Competing Party will not
        directly or indirectly solicit business from, or attempt to sell,
        license or provide the same or similar products or services as are now
        provided to, any customer or client of Land Supplier. Further, for a
        period of One Year after the effective date of this Agreement,
        Non-Competing Party will not directly or indirectly solicit, induce or
        attempt to induce any employee, marketer or partner of Land Supplier to
        terminate his or her agreement with Land Supplier.
      </p>
      <p>
        <strong>3. PAYMENT.</strong> Non-competing Party will pay compensation
        to Land Supplier for the covenants of Non-Competing Party in the amount
        of $100,000.00 per incident. This compensation shall be payable in a
        lump sum No later than 6 months from the violation of this agreement.
      </p>
      <p>
        <strong>4. CONFIDENTIALITY.</strong> Non-Competing Party will not at any
        time or in any manner, either directly or indirectly, use for the
        personal benefit of Non-Competing Party, or divulge, disclose, or
        communicate in any manner any information that is proprietary to Land
        Supplier. Non-Competing Party will protect such information and treat it
        as strictly confidential. The obligation of Non-Competing Party not to
        disclose confidential information shall continue for a period of One
        Year after the effective date of this Agreement. Within 10 Days after
        receiving a written request, Non-Competing Party will return to Land
        Supplier all records, notes, documentation and other items that were
        used, created, or controlled by Non-Competing Party.
      </p>
      <p>
        <strong>5. ENTIRE AGREEMENT.</strong> This Agreement contains the entire
        agreement of the parties regarding the subject matter of this Agreement,
        and there are no other promises or conditions in any other agreement
        whether oral or written.
      </p>
      <p>
        <strong>6. SEVERABILITY.</strong> The parties have attempted to limit
        the noncompete provision so that it applies only to the extent necessary
        to protect legitimate business and property interests. If any provision
        of this Agreement shall be held to be invalid or unenforceable for any
        reason, the remaining provisions shall continue to be valid and
        enforceable. If a court finds that any provision of this Agreement is
        invalid or unenforceable, but that by limiting such provision it would
        become valid and enforceable, then such provision shall be deemed to be
        written, construed, and enforced as so limited.
      </p>
      <p>
        <strong>7. INJUNCTION.</strong> It is agreed that if Non-Competing Party
        violates the terms of this Agreement irreparable harm will occur, and
        money damages will be insufficient to compensate Land Supplier.
        Therefore, Land Supplier will be entitled to seek injunctive relief
        (i.e., a court order that requires Non-Competing Party to comply with
        this Agreement) to enforce the terms of this Agreement. The prevailing
        party shall have the right to collect from the other party its
        reasonable costs and necessary disbursements and attorneys' fees
        incurred in enforcing this Agreement.
      </p>
      <p>
        <strong>8. APPLICABLE LAW.</strong> This Agreement shall be governed by
        the laws of the State of Florida.
      </p>
      <p>
        <strong>9. CONFLICT RESOLUTION.</strong> In the event of a dispute
        between the parties, the parties hereby also agree that the prevailing
        party shall be entitled to reasonable attorney fees and costs incurred
        as a result of the dispute.
      </p>
    </div>
  )
}

export default NoncompeteAgreement
