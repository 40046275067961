import React from 'react'

import styles from './styles.module.scss'

const PageTitle = ({
  title,
  children = null,
  heading = 'h1',
  extraClass = '',
  subtitle = null,
}) => {
  return (
    <div className={`${styles.pageTitle} ${extraClass}`}>
      <div>
        {heading === 'h1' && <h1>{title}</h1>}
        {heading === 'h2' && <h2>{title}</h2>}
        {heading === 'h3' && <h3>{title}</h3>}
        {subtitle && <p>{subtitle}</p>}
      </div>
      {children ? <div className="ms-4">{children}</div> : null}
    </div>
  )
}

export default PageTitle
