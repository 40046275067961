import React from 'react'
import ReactDOM from 'react-dom/client'
import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'
import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { ThemeProvider } from './context/ThemeProvider'
import Router from './router/Router'
import createRootReducer from './redux/reducers'
import rootSaga from './redux/sagas'

import './styles/App.scss'

import reportWebVitals from './reportWebVitals'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

// Middleware
const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() })
const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware, routerMiddleware]
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}
const store = configureStore({
  reducer: createRootReducer(routerReducer),
  middleware: middlewares,
})
sagaMiddleware.run(rootSaga)

const history = createReduxHistory(store)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Elements stripe={stripePromise}>
        <Provider store={store}>
          <Router history={history} />
        </Provider>
      </Elements>
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

export { history }
