import React, { useEffect, useState } from 'react'

import axiosRequest from '../../../../../../utils/axios-request.util'
import axiosErrorUtil from '../../../../../../utils/axios-error.util'

import styles from '../../../styles.module.scss'
import { useLocation } from 'react-router-dom'

const MainTabStats = ({ withData = true }) => {
  const location = useLocation()
  const { pathname } = location
  const [data, setData] = useState({
    properties: {
      properties: 0,
      total: 0,
      active: 0,
      sold: 0,
    },
    leads: {
      leads: 0,
      active: 0,
      closed: 0,
      success: 0,
      conversion: 0,
    },
  })

  useEffect(() => {
    if (withData) {
      axiosRequest('api/v1/analytics/basic', 'get', null, true)
        .then((res) => {
          setData({
            ...data,
            properties: {
              ...data.properties,
              ...res.data.properties,
            },
            leads: {
              ...data.leads,
              ...res.data.leads,
            },
          })
        })
        .catch((e) => axiosErrorUtil(e))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <div className="mt-5 overflow-hidden">
      <h1>Active Stats</h1>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className={styles.cardStats}>
            <h3>Properties</h3>
            <h2>{data.properties.active}</h2>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className={styles.cardStats}>
            <h3>Total Leads</h3>
            <h2>{data.leads.leads}</h2>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className={styles.cardStats}>
            <h3>Closed Leads</h3>
            <h2>{data.leads.closed}</h2>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className={styles.cardStats}>
            <h3>Active Leads</h3>
            <h2>{data.leads.active}</h2>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className={styles.cardStats}>
            <h3>Total Sold</h3>
            <h2>$0</h2>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className={styles.cardStats}>
            <h3>Page Views</h3>
            <h2>0</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainTabStats
