import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../../../assets/logo-2-2.png'
import styles from './sidebar.module.scss'
import SidebarMenu from './partials/Menu'
import SidebarFooter from './partials/Footer'

const Sidebar = ({ user, state, setSidebarState }) => {
  return (
    <div
      className={`${styles.leftSidebar} ${
        !state ? styles.leftSidebarFull : styles.leftSidebarCompact
      }`}
    >
      <div className={styles.outer}>
        <div className={styles.inner}>
          <div className={styles.header}>
            <div className={styles.logo}>
              <Link to="/">
                <img src={logo} alt="LandSupplier" />
              </Link>
            </div>
            <div className={styles.menuHandler}>
              <button onClick={() => setSidebarState(!state)}>
                <span className="d-block d-lg-none">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M24 8L8 24"
                      stroke="#3e914a"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 8L24 24"
                      stroke="#3e914a"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span className="d-none d-lg-block">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z"
                      fill="#3e914a"
                    />
                    <path
                      opacity="0.5"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 6C3 5.44772 3.44772 5 4 5H16C16.5523 5 17 5.44772 17 6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6Z"
                      fill="#3e914a"
                    />
                    <path
                      opacity="0.5"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 18C3 17.4477 3.44772 17 4 17H12C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19H4C3.44772 19 3 18.5523 3 18Z"
                      fill="#3e914a"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <SidebarMenu user={user} setSidebarState={setSidebarState} />
          <SidebarFooter user={user} />
        </div>
      </div>
    </div>
  )
}

export default Sidebar
