import React from 'react'

import styles from './styles.module.scss'

const Terms = () => {
  return (
    <div className={styles.content}>
      <p>
        <strong>Updated: February 28, 2023</strong>
      </p>
      <p>
        This web page represents a legal document and is the Terms and
        Conditions (Agreement) for our website, www.landsupplier.com (Website).
        By using our Website, you agree to fully comply with and be bound by the
        following Agreement each time you use our Website.
      </p>
      <h2 className="h2-mb">Definitions</h2>The terms “us”, “we”, and “our”
      refer to LandSupplier, the owner of this Website. A “Visitor” is someone
      who merely browses our Website. A “User” is someone who has registered
      with our Website to use our Services. The term “User” is a collective
      identifier that refers to either a Visitor or a User. The term “Product”
      refers to any products we sell.
      <p>
        All text, information, graphics, design, and data offered through our
        Website or Services, whether produced by our Users or by us, are
        collectively known as our “Content”. We distinguish content posted by
        our Users as “User Content”.
      </p>
      <h2 className="h2-mb">Acceptance of Agreement</h2>
      <p>This Agreement is between you and LandSupplier.</p>
      <p>
        THIS AGREEMENT CONTAINS WARRANTY DISCLAIMERS AND OTHER PROVISIONS THAT
        LIMIT OUR LIABILITY TO YOU. PLEASE READ THESE TERMS AND CONDITIONS
        CAREFULLY AND IN THEIR ENTIRETY, AS USING, ACCESSING, AND/OR BROWSING
        OUR WEBSITE CONSTITUTES ACCEPTANCE OF THESE TERMS AND CONDITIONS. IF YOU
        DO NOT AGREE TO BE BOUND TO EACH AND EVERY TERM AND CONDITION SET FORTH
        HEREIN, PLEASE EXIT OUR WEBSITE IMMEDIATELY AND DO NOT USE, ACCESS,
        AND/OR BROWSE IT FURTHER.
      </p>
      <p>
        Except as otherwise noted, this Agreement constitutes the entire and
        only Agreement between you and LandSupplier and supersedes all other
        Agreements, representations, warranties, and understandings with respect
        to our Website, Services, and the subject matter contained herein.
        However, for you to use our Website and/or Services, you may also be
        required to agree to additional terms and conditions. Those additional
        terms and conditions will be incorporated into this Agreement unless
        otherwise stated.
      </p>
      <h2 className="h2-mb">Privacy Notice</h2>
      <p>
        Our Privacy Notice is considered part of this Agreement and is available
        on this website. You must review our Privacy Notice by clicking on this
        <a href="/legal/privacy"> link</a>. If you do not accept and agree to be
        bound by all the terms of this Agreement, including the
        www.landsupplier.com Privacy Notice, do not use this Website or our
        Services.
      </p>
      <h2 className="h2-mb">Choice of Law and Jurisdiction</h2>
      <p>
        This Agreement will be treated as if it were executed and performed in
        Fulton County, Georgia, and will be governed by and construed in
        accordance with the laws of the state of Georgia without regard to
        conflict of law provisions. In addition, you agree to submit to the
        personal jurisdiction and venue of such courts. Any cause of action by
        you with respect to our Website or Service must be instituted within one
        (1) year after the cause of action arose or be forever waived and
        barred.
      </p>
      <h2 className="h2-mb">Limited License</h2>
      <p>
        LandSupplier grants you a nonexclusive, nontransferable, revocable
        license to access and use our Website and Services strictly in
        accordance with this Agreement. Your use of our Website and Services is
        solely for internal, personal, noncommercial purposes unless otherwise
        provided for in this Agreement. No printout or electronic version of any
        part of our Website or Services may be used by you in any litigation or
        arbitration matter whatsoever under any circumstances.
      </p>
      <h2 className="h2-mb">Legal Compliance</h2>
      <p>
        You agree to comply with all applicable domestic and international laws,
        statutes, ordinances, and regulations regarding your use of our Website,
        Content, Services, and any software provided therein.
      </p>
      <h2 className="h2-mb">Our Relationship to You</h2>
      <p>
        This Agreement in no way creates any agency, partnership, joint venture,
        or employee-employer or franchisor-franchisee relationship between you
        and LandSupplier.
      </p>
      <h2 className="h2-mb">Our Intellectual Property</h2>
      <p>
        Our Website may contain our service marks or trademarks as well as those
        of our affiliates or other companies in the form of words, graphics, and
        logos. Your use of our Website or Services does not constitute any right
        or license for you to use our service marks or trademarks without the
        prior written permission of LandSupplier.
      </p>
      <p>
        Our Content, as found within our Website and Services, is protected
        under United States and foreign copyrights. The copying, redistribution,
        use, or publication by you of any such Content is strictly prohibited.
        Your use of our Website and Services does not grant you any ownership
        rights to our Content.
      </p>
      <h2 className="h2-mb">Eligibility and Registration for Usership</h2>
      <p>
        To use our Services, you must register with our Website to become a
        User. Your Usership is not transferable or assignable and is void where
        prohibited. Our Website and Services are intended solely for Users who
        are at least (18) years of age or older. Any registration by, use of, or
        access to our Website by anyone under that age is unauthorized,
        unlicensed, and in violation of these Terms and Conditions. By using our
        Website and/or Services, you represent and warrant that you are (18)
        years of age or older and agree to abide by all the terms and conditions
        of this Agreement. LandSupplier has sole right and discretion to
        determine whether to accept a User and may reject a User’s registration
        with or without explanation.
      </p>
      <h2 className="h2-mb">Property Condition</h2>
      <p>
        <strong>
          Buyer understands that the seller is conveying the described property
          "as-is" and buyer agrees to purchase the described property “as-is.”
          Buyer also agrees to do their due diligence inspecting the property
          before they buy it. No representations as to fitness for a particular
          purpose have been made. LandSupplier is not liable in any way for
          property sold on this website. All transactions are between buyer and
          seller, not LandSupplier. No verbal claims or promises have been made
          to buyer which do not appear in writing here. We strongly urge buyers
          and sellers to use a title company to close their transaction.
        </strong>
      </p>
      <p>
        If the above described property is not situated on a public road, then
        buyer hereby acknowledges such, and agrees to hold Seller harmless for
        maintenance and improvements to existing easement(s). Buyer also agrees
        that any improvement or utility bonds will be assumed by the buyer, and
        buyer agrees that if he/she/they did not physically inspect the above
        property, then buyer understands that will not constitute grounds for
        termination of this contract.
      </p>
      <h2 className="h2-mb">Errors, Corrections, and Changes</h2>
      <p>
        We do not represent or otherwise warrant that our Website will be
        error-free or free from viruses or other harmful components, or that we
        will correct any errors. We do not represent or otherwise warrant that
        the information available on or through our Website will be correct,
        accurate, timely, or otherwise reliable. LandSupplier reserves the right
        at our sole discretion to change any content, software, and other items
        used or contained in our Website or Services at any time without notice.
      </p>
      <h2 className="h2-mb">Disclaimer</h2>
      <p>
        Our Website publishes content supplied by third parties, Users,
        Advertisers, Merchants, and Sponsors. Accordingly, LandSupplier has no
        editorial control over such content. Any opinions or other information
        or content expressed or made available by third parties, including
        information providers, Users, or any other user of our Website, are
        those of the respective author(s) and not of LandSupplier. LandSupplier
        does not guarantee the accuracy, completeness, merchantability, or
        fitness for any particular purpose nor the legality of any content
        provided by any of these parties.
      </p>
      <p>
        You understand that we do not operate or control the products or
        services offered by third-party Merchants. These merchants are
        responsible for all aspects of order processing, fulfillment, billing,
        and customer service. We are not a party to the transactions entered
        into between you and Merchants. You agree that use of or purchase from
        such Merchants is AT YOUR SOLE RISK AND WITHOUT WARRANTIES OF ANY KIND
        BY US. All rules, legal documents (including privacy policies), and
        operating procedures of Merchants will apply to you while on any
        Merchant websites.
      </p>
      <p>
        You hereby acknowledge that nothing contained in our Website will
        constitute financial, investment, legal, and/or other professional
        advice and that no professional relationship of any kind is created
        between you and LandSupplier or our Users. You hereby agree that you
        will not make any financial, investment, legal, and/or other decision
        based in whole or in part on anything contained in our Website or
        Services.
      </p>
      <h2 className="h2-mb">Warranty Disclaimer</h2>
      <p>
        LandSupplier is not responsible or liable in any manner for any Content
        posted on our Website or in connection with our Services, whether posted
        or caused by Users of our Website, or by LandSupplier. Although we
        provide rules for User conduct and postings, we do not control and are
        not responsible for what Users post, transmit, or share on our Website
        or Services, and are not responsible for any offensive, inappropriate,
        obscene, unlawful, or otherwise objectionable content you may encounter
        using our Website or Services. LandSupplier is not responsible for the
        online or offline conduct of any User of our Website or Services.
      </p>
      <p>
        Our Website or Services may be temporarily unavailable from time to time
        for maintenance or other reasons. LandSupplier assumes no responsibility
        for any error, omission, interruption, deletion, defect, delay in
        operation or transmission, communications line failure, theft or
        destruction, unauthorized access to, or alteration of User
        communications.
      </p>
      <p>
        LandSupplier is not responsible for any technical malfunction or other
        problems of any telephone network or service, computer system, server or
        provider, computer or mobile phone equipment, or software, or for any
        failure of email on account of technical problems or traffic congestion
        on the Internet, or for any combination thereof – including injury or
        damage to Users’ or any other person’s computer, mobile phone, or other
        hardware or software – related to or resulting from the use or
        downloading of materials in connection with our Website or Services,
        including, without limitation, any software provided through our Website
        or Services.
      </p>
      <p>
        Under no circumstances will LandSupplier be responsible for any loss or
        damage, including any loss or damage, personal injury, or death
        resulting from anyone’s use of our Website or Services, or any
        interactions between Users of our Website or Services, whether online or
        offline.
      </p>
      <p>
        Reference to any products, services, processes, or other information by
        trade name, trademark, manufacturer, supplier, or otherwise does not
        constitute or imply endorsement, sponsorship, recommendation, or any
        affiliation with our Website by third parties or by any of the equipment
        or programming associated with or utilized by our Services.
      </p>
      <p></p>
      <p>
        LandSupplier, INCLUDING ALL OUR AFFILIATES, HAS NO LIABILITY WHATSOEVER
        FOR YOUR USE OF OUR WEBSITE OR SERVICES. LandSupplier CANNOT GUARANTEE
        AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM THE USE OF OUR WEBSITE OR
        SERVICES, INCLUDING, BUT NOT LIMITED TO, RELATED SOFTWARE. LandSupplier
        DOES NOT REPRESENT OR WARRANT THAT OUR CONTENT, SERVICES, OR ANY
        SOFTWARE FOUND WITHIN ARE ACCURATE, COMPLETE, RELIABLE, CURRENT,
        ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THEREFORE,
        YOU SHOULD EXERCISE CAUTION IN THE USE AND DOWNLOADING OF ANY SUCH
        CONTENT OR SOFTWARE AND USE INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND
        REMOVE VIRUSES. ALL RESPONSIBILITY OR LIABILITY FOR ANY DAMAGES CAUSED
        BY VIRUSES SOMEHOW ATTRIBUTED TO OUR CONTENT, SERVICES, AND RELATED
        SOFTWARE IS DISCLAIMED.
      </p>
      <p>
        WITHOUT LIMITING THE FOREGOING, YOU UNDERSTAND AND AGREE THAT YOU
        DOWNLOAD OR OTHERWISE OBTAIN CONTENT AND RELATED SOFTWARE FROM OR
        THROUGH OUR WEBSITE OR SERVICES AT YOUR OWN RISK AND THAT YOU WILL BE
        SOLELY RESPONSIBLE FOR YOUR USE THEREOF AND ANY DAMAGES TO YOUR MOBILE
        DEVICE OR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM OF ANY KIND THAT
        MAY RESULT. WE AND ALL OUR AFFILIATES ARE NOT LIABLE FOR ANY INDIRECT,
        SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR
        LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION, OR THE LIKE), WHETHER
        BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING
        NEGLIGENCE), PRODUCT LIABILITY, OR OTHERWISE, EVEN IF ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. THE NEGATION AND LIMITATION OF DAMAGES SET
        FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN
        YOU AND LandSupplier.
      </p>
      <p>
        OUR WEBSITE AND SERVICES WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS.
        NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
        US THROUGH OUR WEBSITE OR SERVICES WILL CREATE ANY WARRANTY,
        REPRESENTATION, OR GUARANTEE NOT EXPRESSLY STATED IN THIS AGREEMENT.
      </p>
      <h2 className="h2-mb">Limitation of Liability</h2>
      <p>
        IN NO EVENT WILL LandSupplier OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE
        LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING FOR ANY
        LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF OUR WEBSITE, CONTENT,
        SERVICES, OR ANY RELATED SOFTWARE ACCESSED THROUGH OR DOWNLOADED FROM
        OUR WEBSITE OR SERVICES, EVEN IF LandSupplier IS AWARE OR HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </p>
      <h2 className="h2-mb">User Conduct</h2>
      <p>
        Users may post their content to our Website through our Services (User
        Content). Users and Visitors understand that by using our Website or
        Service, they may be exposed to content that is offensive, indecent, or
        objectionable. We have no control over User Content and do not in any
        way guarantee its quality, accuracy, or integrity. LandSupplier is not
        responsible for the monitoring or filtering of any User Content. Should
        any User Content be found illegal, LandSupplier will submit all
        necessary information to relevant authorities.
      </p>
      <p>
        If any User Content is reported to LandSupplier as being offensive or
        inappropriate, we may ask the User to retract or otherwise modify the
        questionable content within 24 hours of being notified by LandSupplier,
        LLC. If the User fails to meet such a request, LandSupplier has full
        authority to restrict the User’s ability to post User Content OR to
        immediately terminate the User’s Usership without further notice to the
        User.
      </p>
      <p>
        Without limiting the foregoing, we have sole discretion to remove any
        User Content that violates this Agreement or that is otherwise
        objectionable in our sole discretion. Users are responsible for
        complying with all applicable federal and state laws for their content,
        including copyright and trademark laws. Users will respect copyright and
        trademark laws.
      </p>
      <p>
        You warrant that you will not use our Services to infringe on the
        intellectual property rights of others in any way. In accordance with
        the DMCA and other applicable law, we have adopted a policy of
        terminating Users whom we deem, in our sole discretion, to be infringers
        of others’ intellectual property rights.
      </p>
      <h2 className="h2-mb">
        As a User, you agree not to use our Services to do any of the following:
      </h2>
      <h5>Upload, post, or otherwise transmit any User Content that:</h5>
      <ol>
        <li>Violates any local, state, federal, or international laws</li>
        <li>
          Infringes on any patent, trademark, trade secret, copyright, or other
          proprietary rights of any party
        </li>
        <li>
          Harms, threatens, defames, promotes violence or illegal activities, or
          is otherwise vulgar, obscene, abusive, harassing, tortuous, libelous,
          invasive of another’s privacy, hateful, or racially, ethically, or
          otherwise objectionable
        </li>
        <li>
          Links directly or indirectly to any materials to which you do not have
          a right to link
        </li>
        <li>
          Contains any private information of any third party, including,
          without limitation, addresses, phone numbers, email addresses, Social
          Security numbers, and credit card numbers
        </li>
        <li>
          Contains software viruses or any other computer code, files, or
          programs designed to interrupt, destroy, or limit the functionality of
          any computer software or hardware or telecommunications equipment, or
          to extract information from our Website or Services
        </li>
        <li>
          Contains any unsolicited or unauthorized advertising, solicitations,
          promotional materials, junk mail, spam, chain letters, pyramid
          schemes, or any other form of solicitation
        </li>
        <li>
          In the sole judgment of LandSupplier is objectionable or restricts or
          inhibits any other person from using or enjoying our Website or
          Services, or which may expose LandSupplier, our affiliates, or our
          Users to any harm or liability of any type
        </li>
      </ol>
      <h5>Use our Content to:</h5>
      <ol>
        <li>Develop a competing website</li>
        <li>
          Create compilations or derivative works as defined under United States
          copyright laws
        </li>
        <li>
          Redistribute it in any manner, including, but not limited to, sale,
          license, lease, rental, subscription, or any other distribution
          mechanism
        </li>
        <li>
          Decompile, disassemble, or reverse engineer our Website, Services, and
          any related software
        </li>
        <li>
          Use our Website or Services in any manner that violates this Agreement
          or any local, state, federal, or international laws
        </li>
      </ol>
      <h2 className="h2-mb">Use of Information</h2>
      <p>
        You grant LandSupplier a license to use the information and materials
        you post to our Website. By posting, displaying, transmitting,
        performing, or otherwise distributing information or other content
        (“User Content”) to our Website, you are granting LandSupplier, its
        officers, directors, employees, agents, consultants, representatives,
        and affiliates, a license to use the User Content in connection with the
        operation of the business of LandSupplier, its directors, employees,
        officers, affiliates, representatives, consultants, and agents,
        including, without limitation, a right to distribute, copy, transmit,
        publicly display, reproduce, translate, edit, and reformat User Content.
        You understand and agree that you will not be compensated for any User
        Content. By posting User Content on our Website or Service, you warrant
        and represent that you own the rights to the User Content or are
        authorized to post, display, distribute, perform, or transmit User
        Content.
      </p>
      <h2 className="h2-mb">Unlawful Activity</h2>
      <p>
        We reserve the right to investigate complaints or reported violations of
        this Agreement and to take any action we deem appropriate, including,
        but not limited to, reporting any suspected unlawful activity to law
        enforcement officials, regulators, or other third parties and disclosing
        any information necessary or appropriate to such persons or entities
        relating to your profile, email addresses, usage history, posted
        materials, IP addresses, and traffic information.
      </p>
      <h2 className="h2-mb">Linking to Our Website</h2>
      <p>
        You may provide links to our Website provided that (a) you do not remove
        or obscure any portion of our Website by framing or otherwise, (b) your
        website does not engage in illegal or pornographic activities, and (c)
        you cease providing links to our Website immediately upon our request.
      </p>
      <h2 className="h2-mb">Links to Other Websites</h2>
      <p>
        Our Website may from time to time contain links to third-party websites.
        Inclusion of links for any website on our Website does not mean that we
        endorse, guarantee, warrant, or recommend the services, information,
        content, and/or data of such third-party websites.
      </p>
      <p>
        LandSupplier has no control over the legal documents and privacy
        practices of third-party websites; you access any third-party websites
        at your own risk. We recommend that you review the privacy notice and
        terms and conditions of those websites to fully understand what
        information is collected and how it is used.
      </p>
      <h2 className="h2-mb">Payments</h2>
      <p>
        You represent and warrant that if you are purchasing something from us,
        (i) any payment information you supply is true and complete, (ii)
        charges incurred by you will be honored by your bank or credit card
        company, (iii) you will pay the charges incurred by you at the posted
        prices, including any applicable taxes, and (iv) if your initial payment
        method is dishonored, you will still pay the incurred charges, including
        any surcharge we may incur due to the dishonored payment.
      </p>
      <h2 className="h2-mb">Refund Policy</h2>
      <p></p>
      <p>
        When a buyer submits a deposit they have an option to request a refund
        for the deposit within 48 hours and it will be refunded. The refund is
        only valid for 48 hours from a submission time.
      </p>
      <h2 className="h2-mb">Termination of Usership</h2>
      <p>
        Your Usership with us is effective until terminated by you or us. Your
        rights under these Terms and Conditions will terminate without our
        notice if you fail to comply with any term of these Terms and
        Conditions. Upon termination, you will stop representing yourself as a
        registered User or Client. You must delete or destroy any information or
        content (including all copies) obtained from our Website. Certain
        provisions of this Agreement, including, but not limited to, copyrights,
        indemnity, trademarks, limitation of liability, warranty, and
        jurisdictional issues will survive the termination of this Agreement.
      </p>
      <h2 className="h2-mb">Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold us and our partners, agents,
        officers, directors, employees, subcontractors, successors, assigns,
        third-party suppliers of information and documents, attorneys,
        advertisers, product and service providers, and affiliates free from any
        liability, loss, claim, and expense, including reasonable attorney’s
        fees, related to your violation of this Agreement or use of our Website
        or Services.
      </p>
      <h2 className="h2-mb">Severability and Survival</h2>
      <p>
        Should any part of this Agreement be held invalid or unenforceable, that
        portion will be construed consistent with applicable law and the
        remaining portions will remain in full force and effect. To the extent
        that any Content is in conflict or inconsistent with this Agreement,
        this Agreement will take precedence. Our failure to enforce any
        provision of this Agreement will not be deemed a waiver of such a
        provision, nor of the right to enforce such a provision. Our rights
        under this Agreement will survive any termination of this Agreement.
      </p>
      <h2 className="h2-mb">Changes to Our Terms and Conditions</h2>
      <p>
        We reserve the right to change these Terms and Conditions at any time by
        giving you advance notice of the changes by email or in writing. We will
        also post these changes on our website. These changes will become
        effective 30 days after receipt of the notice. To avoid doubt, no
        unilateral amendment will retroactively change agreed dispute-resolution
        provisions of these Terms and Conditions, if any, including, for
        example, arbitration provisions for then-pending disputes unless the
        parties expressly agree otherwise. Your continued use of our Website,
        Services, and Products after any change to these Terms and Conditions
        and our notifying you will constitute your acceptance of such change. If
        you do not agree with the changes to these Terms and Conditions, you can
        choose to discontinue the use of our Website, Services, and Products.
      </p>
      <p>
        Copyright © This Terms and Conditions is protected under United States
        and foreign copyrights. The copying, redistribution, use or publication
        by you, is strictly prohibited.
      </p>
    </div>
  )
}

export default Terms
