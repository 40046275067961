import React, { useState } from 'react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux'
import { Button, Form, notification } from 'antd'

import LSModal from '../modal'

import axiosRequest from '../../../utils/axios-request.util'
import axiosErrorUtil from '../../../utils/axios-error.util'

const SubscribeModal = ({ visible, setVisibility, subscription }) => {
  const users = useSelector((state) => state.users)
  const { user } = users
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(false)

  if (!subscription) {
    return null
  }

  const handleSubscription = (event) => {
    // Block native form submission.
    event.preventDefault()
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement)
    const isInvalid = cardElement._invalid
    const isEmpty = cardElement._empty
    if (isEmpty || isInvalid) {
      notification.error({
        message: 'Error',
        description: 'Please, check your inputs',
      })
      return null
    }
    setLoading(true)
    axiosRequest(
      'api/v1/users/billing/subscription/create-subscription',
      'post',
      { price_id: subscription.price_id },
      true,
    )
      .then((subscriptionData) => {
        const { client_secret, payment_id, subscription_id } =
          subscriptionData.data
        stripe
          .confirmCardPayment(client_secret, {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: user.name,
              },
            },
          })
          .then((result) => {
            if (result.error) {
              setLoading(false)
              console.log(result)
              notification.error({
                message: 'Error',
                description:
                  result.error.message ||
                  'Something goes wrong, please, try again later',
              })
            } else {
              axiosRequest(
                'api/v1/users/billing/subscription/finish-subscription',
                'post',
                {
                  amount: subscription.price,
                  payment_id,
                  subscription_id,
                  subscription_name: subscription.name,
                },
                true,
              )
                .then(() => {
                  setLoading(false)
                  window.location.reload()
                })
                .catch((e) => axiosErrorUtil(e))
                .finally(() => setLoading(false))
            }
          })
      })
      .catch((e) => {
        axiosErrorUtil(e)
        setLoading(false)
      })
  }

  return (
    <LSModal
      title={subscription.name}
      visible={visible}
      setVisibility={setVisibility}
      width={640}
    >
      <Form layout="vertical" className="row">
        <Form.Item label="Card Number" className="col-12">
          <div className="stripe-input-wrapper">
            <CardNumberElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#000000',
                    fontWeight: '400',
                  },
                },
              }}
            />
          </div>
        </Form.Item>
        <Form.Item label="Expire Date" className="col-12 col-md-6">
          <div className="stripe-input-wrapper">
            <CardExpiryElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#000000',
                    fontWeight: '400',
                  },
                },
              }}
            />
          </div>
        </Form.Item>
        <Form.Item label="CVC" className="col-12 col-md-6">
          <div className="stripe-input-wrapper">
            <CardCvcElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#000000',
                    fontWeight: '400',
                  },
                },
              }}
            />
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="button"
            className="btn-primary"
            onClick={(e) => handleSubscription(e)}
            loading={loading}
          >
            Subscribe
          </Button>
        </Form.Item>
      </Form>
    </LSModal>
  )
}

export default SubscribeModal
