import React from 'react'

import VideoModal from '../../../../../video-modal'

import styles from './how-it-works.module.scss'

const HowItWorks = ({
  link,
  extraClass = null,
  title = null,
  blockTitle = null,
  hideSubTitle = false,
}) => {
  const [video, setVideo] = React.useState({
    visible: false,
    link: '',
    title: '',
  })

  return (
    <>
      <div className={`${styles.howItWorks} ${extraClass || ''}`}>
        <button
          type="button"
          onClick={() => setVideo({ visible: true, link, title })}
        >
          <div className={styles.icon}>
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="50"
                cy="50"
                r="34"
                fill="rgba(62, 145, 74, 0.2)"
                fillOpacity="0.23"
              />
              <g filter="url(#filter0_d_2660_20399)">
                <circle
                  cx="50.0022"
                  cy="50"
                  r="29.1429"
                  fill="url(#paint0_linear_2660_20399)"
                />
                <circle
                  cx="50.0022"
                  cy="50"
                  r="28.1429"
                  stroke="white"
                  strokeOpacity="0.18"
                  strokeWidth="2"
                />
              </g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.2305 60.2466V39.7523C38.2305 37.5656 40.6812 36.2075 42.6119 37.32L60.3505 47.571C62.2417 48.6682 62.2417 51.3384 60.3505 52.4279L42.6119 62.6789C40.6812 63.7915 38.2305 62.441 38.2305 60.2466Z"
                fill="white"
              />
              <defs>
                <filter
                  id="filter0_d_2660_20399"
                  x="0.859375"
                  y="0.857117"
                  width="98.2852"
                  height="98.2857"
                  filterUnits="userSpaceOnUse"
                  // color-interpolation-filters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="10" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2660_20399"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2660_20399"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_2660_20399"
                  x1="27.684"
                  y1="31.924"
                  x2="67.5248"
                  y2="71.2115"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3e914a" />
                  <stop offset="1" stopColor="#65cb73" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className={styles.content}>
            <h3>{blockTitle || 'How It Works'}</h3>
            {!hideSubTitle && <p className="mb-0">Watch video</p>}
          </div>
        </button>
      </div>
      <VideoModal
        visible={video.visible}
        onClose={() => setVideo({ visible: false, video: null, title: '' })}
        title={video.title}
        videoLink={video.link}
      />
    </>
  )
}

export default HowItWorks
