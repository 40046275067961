import { lazy } from 'react'

const DashboardPage = lazy(() => import('../pages/DashboardPage'))
const SubscriptionListPage = lazy(() => import('../pages/SubscriptionsPage'))
const PropertiesPage = lazy(() => import('../pages/PropertiesPage'))
const AnalyticsPage = lazy(() => import('../pages/AnalyticsPage'))
const GeneralSettingsPage = lazy(() =>
  import('../pages/settings/GeneralSettingsPage'),
)
const ChangePasswordPage = lazy(() =>
  import('../pages/settings/ChangePasswordPage'),
)
const BillingPage = lazy(() => import('../pages/settings/BillingPage'))
const SubscriptionPage = lazy(() =>
  import('../pages/settings/SubscriptionPage'),
)
const LeadsPage = lazy(() => import('../pages/crm/LeadsPage'))
const CustomersPage = lazy(() => import('../pages/crm/CustomersPage'))
const NewslettersPage = lazy(() =>
  import('../pages/newsletters/NewslettersPage'),
)
const NewslettersFormPage = lazy(() =>
  import('../pages/newsletters/NewslettersFormPage'),
)
const TextMessagesPage = lazy(() => import('../pages/TextMessagesPage'))

const appRoutes = [
  {
    path: '/',
    Component: DashboardPage,
  },
  {
    path: '/subscription',
    Component: SubscriptionListPage,
  },
  {
    path: '/properties/:slug',
    Component: PropertiesPage,
  },
  {
    path: '/analytics',
    Component: AnalyticsPage,
  },
  {
    path: '/crm/leads',
    Component: LeadsPage,
  },
  {
    path: '/crm/customers',
    Component: CustomersPage,
  },
  {
    path: '/newsletters',
    Component: NewslettersPage,
  },
  {
    path: '/newsletters/:slug',
    Component: NewslettersFormPage,
  },
  {
    path: '/text-messages',
    Component: TextMessagesPage,
  },
  {
    path: '/settings',
    Component: GeneralSettingsPage,
  },
  {
    path: '/settings/change-password',
    Component: ChangePasswordPage,
  },
  {
    path: '/settings/subscription',
    Component: SubscriptionPage,
  },
  {
    path: '/settings/billing',
    Component: BillingPage,
  },
]

export default appRoutes
