import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'antd'

import PageTitle from '../../page-title'
import HowItWorks from '../../properties/modals/boost-modal/partials/content/HowItWorks'

import actions from '../../../../redux/modals/actions'

import styles from './subscriptions.module.scss'

const Subscriptions = ({
  data,
  activePlan,
  setContactModal,
  setSubscribe,
  userSubscription,
}) => {
  const dispatch = useDispatch()
  const [active, setActive] = useState(activePlan)

  return (
    <div className={styles.subscriptions}>
      <div className="row mb-5">
        <div
          className={`col-12 col-md-6 col-xl-5 offset-xl-1 mb-4 mb-lg-0 ${styles.left}`}
        >
          {data.map((item) => (
            <button
              key={item.price_id}
              className={`${
                active.price_id === item.price_id
                  ? styles.subscriptionButtonActive
                  : styles.subscriptionButton
              }`}
              onClick={() => setActive(item)}
            >
              <div className={styles.name}>
                {item.name === 'Starting' ? 'Partnership' : item.name}
              </div>
              <div className={styles.price}>
                {['Expert', 'Enterprise'].includes(item.name) ? (
                  <Button
                    className="btn-sm btn-primary__light"
                    onClick={() => setContactModal(true)}
                  >
                    Contact Us
                  </Button>
                ) : (
                  <>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 0,
                    }).format(
                      item.discount
                        ? (item.price - item.discount) / 100
                        : item.price / 100,
                    )}
                    {item.discount && (
                      <sup>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 0,
                        }).format(item.price / 100)}
                      </sup>
                    )}
                    <span>/month</span>
                  </>
                )}
              </div>
            </button>
          ))}
        </div>
        <div className="col-12 col-md-6 col-xl-5 mb-4 mb-lg-0">
          <div className={styles.right}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <PageTitle
                title={`${active.name} Plan`}
                heading="h3"
                extraClass="mb-1"
              />
              <p className={styles.muted}>{active.description}</p>
              <div className="mb-4">
                <HowItWorks link={active.video} />
              </div>
            </div>
            <div className={styles.features}>
              {active.features.map((feature, index) => (
                <div className={styles.feature} key={index}>
                  <div className="me-3">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="#3e914a"
                      ></rect>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="#3e914a"
                      ></path>
                    </svg>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: feature }} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mb-3">
        <p>
          By continue, you agree with our{' '}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              dispatch({
                type: actions.LEGAL_MODAL,
                payload: {
                  legal: {
                    visible: true,
                    type: 'terms',
                  },
                },
              })
            }}
          >
            Terms And Conditions
          </a>{' '}
          and{' '}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              dispatch({
                type: actions.LEGAL_MODAL,
                payload: {
                  legal: {
                    visible: true,
                    type: 'privacy',
                  },
                },
              })
            }}
          >
            Privacy Policy
          </a>
        </p>
        <p>
          By continue, you agree with our{' '}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              dispatch({
                type: actions.LEGAL_MODAL,
                payload: {
                  legal: {
                    visible: true,
                    type: 'noncompete',
                  },
                },
              })
            }}
          >
            Noncompete Agreement
          </a>
        </p>
      </div>
      {active && !['Expert', 'Enterprise'].includes(active.name) ? (
        <div className="text-center mb-4">
          <Button
            htmlType="button"
            className="btn-primary"
            onClick={() => {
              if (!userSubscription) {
                setSubscribe({ visible: true, subscription: active })
              } else {
                if (
                  userSubscription &&
                  userSubscription.plan.id === active.price_id
                ) {
                  return null
                } else {
                  setContactModal(true)
                }
              }
            }}
          >
            {userSubscription
              ? userSubscription && userSubscription.plan.id === active.price_id
                ? 'Current Plan'
                : 'Upgrade Plan'
              : 'Select Plan'}
          </Button>
        </div>
      ) : null}
      <div className="text-center">
        <p className="muted-color mb-0">Powered by Stripe</p>
      </div>
    </div>
  )
}

export default Subscriptions
