import React from 'react'

import LSModal from '../modal'

import styles from './how-it-works-modal.module.scss'

const HowItWorksTextModal = ({ visible, setVisibility }) => {
  return (
    <LSModal
      title="How It Works"
      visible={visible}
      setVisibility={setVisibility}
      width={'100%'}
      extraClassName="full-page-modal full-page-modal__subscription full-page-modal__no-paddings"
    >
      <>
        <div className="full-page-modal__container full-page-modal__container-small mb-5">
          <div className={styles.howItWorks}>
            <h3 className={styles.title}>Why are we unique and different?</h3>
            <div className={styles.blocks}>
              <div className="row">
                <div className="col-12 col-md-6 mb-md-5">
                  <div className={styles.block}>
                    <div>
                      <p>
                        <strong>Business Model</strong> (acquisition, properties
                        database, education, support, weekly webinars, etc.)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-md-5">
                  <div className={styles.block}>
                    <div>
                      <p>
                        <strong>Dashboard</strong> (ultimate workspace to run
                        Real Estate business: CRM, newsletters, text messaging,
                        marketing, leads, boosts, etc.)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className={styles.title}>
              Business Model -{' '}
              <span>
                acquisition, properties database, education, support and weekly
                webinars.
              </span>
            </h3>
            <div className={styles.blocks}>
              <div className={styles.block}>
                <div className={styles.number}>
                  <div className={styles.numberWrapper}>
                    <span>1</span>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>
                      A-to-Z comprehensive resources for wholesaling real estate
                    </strong>{' '}
                    - we are a one-stop resource for all things related to real
                    estate. Whether you are a seasoned professional or just
                    starting in the industry, we have something for you. With
                    our complete, all-inclusive support, you'll have everything
                    you need to succeed in the exciting world of real estate.
                  </p>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.number}>
                  <div className={styles.numberWrapper}>
                    <span>2</span>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>Acquisition & properties database</strong> - as you
                    know, this is the most crucial aspect of the business. It’s
                    a foundation that takes energy, effort, resources, and
                    funds! WE DO IT FOR YOU! The properties are delivered
                    directly to your dashboard.
                  </p>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.number}>
                  <div className={styles.numberWrapper}>
                    <span>3</span>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>Education & Support</strong> - most of the education
                    programs charge heavy fees. It’s part of our partnership and
                    subscription plans, and we will support you as much as you
                    need. We want you to succeed with us. It benefits both
                    parties. We simplified all things for you. All you do is
                    focus on sales and scaling up. We do have a solid approach
                    to how to generate sales.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.howItWorks} ${styles.howItWorksGray}`}>
          <div className="full-page-modal__container full-page-modal__container-small">
            <h3 className={styles.title}>
              Dashboard -{' '}
              <span>ultimate workspace to run a Real Estate business!</span>
            </h3>
            <div className={styles.blocks} style={{ marginBottom: 0 }}>
              <div className={styles.block}>
                <div className={styles.number}>
                  <div className={styles.numberWrapper}>
                    <span>1</span>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>Website Activation</strong> - we will set up and
                    activate your website with the custom domain name and all
                    properties. Your email and phone number will be added, and
                    buyers can contact you directly.
                  </p>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.number}>
                  <div className={styles.numberWrapper}>
                    <span>2</span>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>CRM</strong> - customized and simplified all-in-one
                    CRM tailored to grow your business.
                  </p>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.number}>
                  <div className={styles.numberWrapper}>
                    <span>3</span>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>Newsletters</strong> - refined, adapted and
                    automated for you. You don’t need to spend time on research
                    or hire someone to help you set things up. We did it for
                    you.
                  </p>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.number}>
                  <div className={styles.numberWrapper}>
                    <span>4</span>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>Text Messaging</strong> - same as newsletters. All
                    you need to start using it. All tools are integrated and
                    automated directly in your dashboard.
                  </p>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.number}>
                  <div className={styles.numberWrapper}>
                    <span>5</span>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>Marketing & Leads</strong> - this is the only one
                    offering something similar. We promote the properties on
                    Facebook Marketplace, FB groups, Craigslist, OfferUp, and
                    other online portals. Collect direct-targeted leads for a
                    specific property, and then offer for the lowest price
                    available on the market directly in your dashboard. You will
                    receive a notification in your dashboard and emails.
                  </p>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.number}>
                  <div className={styles.numberWrapper}>
                    <span>6</span>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>Boost</strong> - additional marketing available for
                    you. You can promote the listings for small fees to get more
                    leads in more than 200 Facebook real estate groups for $0.20
                    cents per group post. Also, we do offer Craigslist marketing
                    for you. For details, you can click on the “boost” tab and
                    watch short videos.
                  </p>
                </div>
              </div>
              <div className={styles.block} style={{ marginBottom: 0 }}>
                <div className={styles.number}>
                  <div className={styles.numberWrapper}>
                    <span>7</span>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>Pay-As-You-Need</strong> - includes all features we
                    mentioned in the packages except leads and boosts,
                    newsletters, and text messages included. If you exceed the
                    limits, you only need to add some funds to your balance and
                    use them as you need. This is a very cool feature.
                    Newsletter price: <i> $0.0025/per email</i>. Text messaging:
                    <i> $0.04-$0.05 cents per text message</i>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="full-page-modal__container full-page-modal__container-small mt-5">
          <div className={styles.howItWorks}>
            <h3 className={styles.title}>
              This program is by invitation only. You will have{' '}
              <span>seven days</span> of free access to make a decision.
            </h3>
            <h3 className={styles.title}>
              After <span>seven days</span> trial period, if you don’t
              subscribe, your access will be deactivated.
            </h3>
          </div>
        </div>
      </>
    </LSModal>
  )
}

export default HowItWorksTextModal
