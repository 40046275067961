import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import mainSidebarMenu from '../../../../data/menu/sidebar/main'
import adminSidebarMenu from '../../../../data/menu/sidebar/admin'

import styles from '../sidebar.module.scss'

const SidebarMenu = ({ user, setSidebarState }) => {
  const [active, setActive] = useState(null)
  const menu = user && user.is_admin ? adminSidebarMenu : mainSidebarMenu

  const handleClick = () => {
    if (window.innerWidth < 1024) {
      setSidebarState(false)
    }
  }

  return (
    <div className={styles.sidebarMenu}>
      <ul>
        {menu.map(({ id, path, name, icon, isMobileOnly, childs }) => (
          <li key={id} className={isMobileOnly ? styles.mobileOnly : ''}>
            <Link
              to={path}
              onClick={(e) => {
                if (childs && childs.length > 0) {
                  e.preventDefault()
                  if (active === id) {
                    setActive(null)
                  } else {
                    setActive(id)
                  }
                } else {
                  handleClick()
                }
              }}
            >
              <div className={styles.icon} data-type="advertiser">
                {icon}
              </div>
              <div className={styles.name}>{name}</div>
              {childs && childs.length > 0 ? (
                <div
                  className={`${styles.arrow} ${
                    active === id ? styles.arrow__active : ''
                  }`}
                >
                  <button onClick={() => setActive(id)}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.6 4L9.6 12L17.6 20H13.6L6.3 12.7C5.9 12.3 5.9 11.7 6.3 11.3L13.6 4H17.6Z"
                        fill="#3e914a"
                      />
                    </svg>
                  </button>
                </div>
              ) : null}
            </Link>
            {childs && childs.length > 0 ? (
              <ul className={active && active === id ? styles.active : ''}>
                {childs.map((child) => (
                  <li key={child.path}>
                    <Link to={child.path} onClick={() => handleClick()}>
                      {child.name}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SidebarMenu
