import { lazy } from 'react'

const AdminPropertiesPage = lazy(() => import('../pages/admin/PropertiesPage'))
const AdminLeadsPage = lazy(() => import('../pages/admin/LeadsPage'))
const AdminUsersPage = lazy(() => import('../pages/admin/UsersPage'))

const adminRoutes = [
  {
    path: '/admin/properties',
    Component: AdminPropertiesPage,
  },
  {
    path: '/admin/leads',
    Component: AdminLeadsPage,
  },
  {
    path: '/admin/users/:slug',
    Component: AdminUsersPage,
  },
]

export default adminRoutes
