import axios from 'axios'

import localStorageHelper from './local-storage.util'

axios.defaults.withCredentials = true

const axiosRequest = (url, method, data, needBearerToken = false) => {
  const API_URL = process.env.REACT_APP_SERVER_URL
  let headers = {
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  }
  const bearerToken = localStorageHelper.get('apiToken')
  if (needBearerToken) {
    headers = {
      ...headers,
      headers: {
        ...headers.headers,
        Authorization: `Bearer ${bearerToken}`,
      },
    }
  }

  switch (method) {
    case 'get':
      return axios.get(API_URL + url, headers)
    case 'post':
      return axios.post(API_URL + url, data, headers)
    case 'put':
      return axios.put(API_URL + url, data, headers)
    case 'patch':
      return axios.patch(API_URL + url, data, headers)
    case 'delete':
      return axios.delete(API_URL + url, {
        data: {},
        headers: { Authorization: `Bearer ${bearerToken}` },
      })
    default:
      return axios.get(API_URL + url, headers)
  }
}

export default axiosRequest
