import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from 'antd'

import LSModal from '../modal'
import Subscriptions from './list-modal-partials/Subscriptions'

import actions from '../../../redux/modals/actions'
import axiosRequest from '../../../utils/axios-request.util'
import axiosErrorUtil from '../../../utils/axios-error.util'
import SubscribeModal from './SubscribeModal'
import ContactModal from '../../layouts/right-sidebar/partials/ContactModal'

const SubscriptionsListModal = () => {
  const dispatch = useDispatch()
  const modals = useSelector((state) => state.modals)
  const users = useSelector((state) => state.users)
  const { subscriptionModal } = modals
  const { subscription } = users
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [contactModal, setContactModal] = useState(false)
  const [subscribe, setSubscribe] = useState({
    visible: false,
    subscription: null,
  })

  useEffect(() => {
    if (subscriptionModal) {
      setLoading(true)
      axiosRequest('api/v1/users/billing/subscription', 'get', null, true)
        .then((res) => {
          setData(res.data.subscriptions)
        })
        .catch((e) => axiosErrorUtil(e))
        .finally(() => setLoading(false))
    }
  }, [subscriptionModal])

  const onClose = () => {
    dispatch({
      type: actions.SUBSCRIPTION,
      payload: {
        subscriptionModal: false,
      },
    })
  }

  const handleActivePlan = () => {
    if (!subscription) {
      return data[0]
    } else {
      const index = _.findIndex(
        data,
        (v) => v.price_id === subscription.plan.id,
      )
      return index >= 0 ? data[index] : data[0]
    }
  }

  return (
    <LSModal
      visible={subscriptionModal}
      setVisibility={() => onClose()}
      title="Choose Plan"
      width="100%"
      extraClassName="full-page-modal full-page-modal__subscription"
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="full-page-modal__container">
          <Subscriptions
            data={data}
            userSubscription={subscription}
            setContactModal={setContactModal}
            setSubscribe={setSubscribe}
            activePlan={handleActivePlan()}
          />
        </div>
      )}
      <SubscribeModal
        visible={subscribe.visible}
        setVisibility={() =>
          setSubscribe({
            visible: false,
            subscription: null,
          })
        }
        subscription={subscribe.subscription}
      />
      <ContactModal
        visible={contactModal}
        setVisibility={() => setContactModal(false)}
      />
    </LSModal>
  )
}

export default SubscriptionsListModal
