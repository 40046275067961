import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LSModal from '../modal'
import Terms from './content/Terms'
import Privacy from './content/Privacy'
import NoncompeteAgreement from './content/NoncompeteAgreement'

import actions from '../../../redux/modals/actions'

const TermsModal = () => {
  const dispatch = useDispatch()
  const { legal } = useSelector((state) => state.modals)

  if (!legal.visible || legal.type === null || legal.type === '') {
    return null
  }

  const handleTitle = () => {
    switch (legal.type) {
      case 'terms':
        return 'Terms And Conditions'
      case 'privacy':
        return 'Privacy Policy'
      case 'noncompete':
        return 'Noncompete Agreement'
      default:
        return 'Modal'
    }
  }

  return (
    <LSModal
      title={handleTitle()}
      visible={legal.visible}
      setVisibility={() => {
        dispatch({
          type: actions.LEGAL_MODAL,
          payload: {
            legal: {
              visible: false,
              type: '',
            },
          },
        })
      }}
      width={'100%'}
      extraClassName="full-page-modal full-page-modal__subscription"
    >
      <div className="full-page-modal__container full-page-modal__container-small">
        {legal.type === 'terms' && <Terms />}
        {legal.type === 'privacy' && <Privacy />}
        {legal.type === 'noncompete' && <NoncompeteAgreement />}
      </div>
    </LSModal>
  )
}

export default TermsModal
