import React, { useEffect } from 'react'
import PerfectScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'

import rightSidebarTabs from '../../../data/menu/right-sidebar/main'
import adminRightSidebarTabs from '../../../data/menu/right-sidebar/admin'

import styles from './styles.module.scss'
import MainTab from './partials/main/MainTab'
import ContactUs from './partials/main/ContactUs'
import NotificationsRightSidebar from './partials/main/Notifications'
import actions from '../../../redux/modals/actions'
import VideoLibrary from './partials/main/VideoLibrary'

const RightSidebar = ({
  user,
  rightSidebar,
  rightSidebarState,
  setRightSidebarState,
}) => {
  const ref = React.createRef()
  const modals = useSelector((state) => state.modals)
  const menu = user && user.is_admin ? adminRightSidebarTabs : rightSidebarTabs
  const dispatch = useDispatch()
  const [title, setTitle] = React.useState('Product & Services')

  useEffect(() => {
    switch (modals.rightSidebarActiveTab) {
      case 1:
        setTitle('General')
        break
      case 2:
        setTitle('Video Library')
        break
      case 3:
        setTitle('Notifications')
        break
      case 4:
        setTitle('Contact Us')
        break
      default:
        setTitle('General')
        break
    }
  }, [modals.rightSidebarActiveTab])

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        rightSidebarState &&
        ref &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setRightSidebarState(!rightSidebarState)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line
  }, [ref])

  const handleRightSidebar = (state) => {
    dispatch({
      type: actions.RIGHT_SIDEBAR,
      payload: {
        rightSidebar: state,
      },
    })
  }

  return (
    <>
      <div
        className={`${styles.rightSidebar} ${
          rightSidebar ? styles.rightSidebarOpen : ''
        } ${rightSidebar ? styles.rightSidebarHidden : ''}`}
        ref={ref}
      >
        <div
          className={`${styles.toggle} d-md-none ${
            !rightSidebar ? 'd-none' : ''
          }`}
        >
          <button onClick={() => handleRightSidebar(!rightSidebar)}>
            <svg
              width="32"
              height="45"
              viewBox="0 0 32 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.85185C0 4.43358 3.58172 0.851854 8 0.851854H32V44.8519H8C3.58172 44.8519 0 41.2701 0 36.8519V8.85185Z"
                fill="#3e914a"
              />
              <path
                d="M19 28.8519L13 22.8519L19 16.8519"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className={styles.header}>
          {menu.map(({ key, icon }) => (
            <div key={key} className={styles.headerTab}>
              <button
                className={
                  modals.rightSidebarActiveTab === key ? styles.active : ''
                }
                onClick={() => {
                  dispatch({
                    type: actions.SET_RIGHT_SIDEBAR_TAB,
                    payload: {
                      rightSidebarActiveTab: key,
                      // rightSidebar: false,
                    },
                  })
                }}
              >
                {icon}
              </button>
            </div>
          ))}
        </div>
        <div className={styles.content}>
          <PerfectScrollBar>
            {modals.rightSidebarActiveTab !== 3 && <h1>{title}</h1>}
            {modals.rightSidebarActiveTab === 4 && (
              <h4>
                Regarding the details and any questions, please get in touch
                with Robert directly.
              </h4>
            )}
            {user && user.is_admin ? null : (
              <>
                {modals.rightSidebarActiveTab === 1 && <MainTab />}
                {modals.rightSidebarActiveTab === 2 && <VideoLibrary />}
                {modals.rightSidebarActiveTab === 3 && (
                  <NotificationsRightSidebar />
                )}
                {modals.rightSidebarActiveTab === 4 && <ContactUs />}
              </>
            )}
          </PerfectScrollBar>
        </div>
      </div>
    </>
  )
}

export default RightSidebar
