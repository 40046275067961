import React from 'react'

const rightSidebarTabs = [
  {
    key: 1,
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path
            d="M5.33301 28V18.6667"
            stroke="#3e914a"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.33301 13.3333V4"
            stroke="#3e914a"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 28V16"
            stroke="#3e914a"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 10.6667V4"
            stroke="#3e914a"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.667 28V21.3333"
            stroke="#3e914a"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.667 16V4"
            stroke="#3e914a"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.33301 18.6667H9.33301"
            stroke="#3e914a"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 10.6667H20"
            stroke="#3e914a"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.667 21.3333H30.667"
            stroke="#3e914a"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    ),
  },
  {
    key: 2,
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path
            d="M6.66663 4L25.3333 16L6.66663 28V4Z"
            stroke="#3e914a"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    ),
  },
  {
    key: 3,
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path
            d="M18.05 26.804C17.8417 27.1678 17.5426 27.4697 17.1828 27.6796C16.8229 27.8895 16.4149 28 15.9997 28C15.5844 28 15.1764 27.8895 14.8166 27.6796C14.4568 27.4697 14.1577 27.1678 13.9493 26.804M23.1108 11.2013C23.1108 9.29138 22.3616 7.45971 21.028 6.1092C19.6944 4.7587 17.8857 4 15.9997 4C14.1137 4 12.305 4.7587 10.9714 6.1092C9.63777 7.45971 8.88856 9.29138 8.88856 11.2013C8.88856 19.6028 5.33301 22.0032 5.33301 22.0032H26.6663C26.6663 22.0032 23.1108 19.6028 23.1108 11.2013Z"
            stroke="#3e914a"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    ),
  },
  {
    key: 4,
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path
            d="M5.33366 5.33334H26.667C28.1337 5.33334 29.3337 6.53334 29.3337 8V24C29.3337 25.4667 28.1337 26.6667 26.667 26.6667H5.33366C3.86699 26.6667 2.66699 25.4667 2.66699 24V8C2.66699 6.53334 3.86699 5.33334 5.33366 5.33334Z"
            stroke="#3e914a"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.3337 8L16.0003 17.3333L2.66699 8"
            stroke="#3e914a"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    ),
  },
]

export default rightSidebarTabs
