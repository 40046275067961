import { lazy } from 'react'

const LoginPage = lazy(() => import('../pages/auth/LoginPage'))
const RestorePasswordPage = lazy(() =>
  import('../pages/auth/RestorePasswordPage'),
)

const authRoutes = [
  {
    path: '/secure/login',
    Component: LoginPage,
  },
  {
    path: '/secure/restore',
    Component: RestorePasswordPage,
  },
]

export default authRoutes
