import { all, takeEvery, put } from 'redux-saga/effects'

import actions from './actions'

export function* handleSubscriptionModal({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      ...payload,
    },
  })
}

export function* handleRightSidebar({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      ...payload,
    },
  })
}

export function* handleLegalModal({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      ...payload,
    },
  })
}

export function* setActiveRightSidebarTab({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      ...payload,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SUBSCRIPTION, handleSubscriptionModal),
    takeEvery(actions.RIGHT_SIDEBAR, handleRightSidebar),
    takeEvery(actions.LEGAL_MODAL, handleLegalModal),
    takeEvery(actions.SET_RIGHT_SIDEBAR_TAB, setActiveRightSidebarTab),
  ])
}
