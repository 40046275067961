import React from 'react'

import LSModal from '../../../ui/modal'

const ContactModal = ({ visible, setVisibility }) => {
  return (
    <LSModal
      width={520}
      visible={visible}
      setVisibility={setVisibility}
      title="Contact Us"
    >
      <p>
        Regarding the details and any questions, please get in touch with Robert
        directly.
      </p>
      <p>
        Email: <strong>robert@landsupplier.com</strong>
      </p>
      <p>
        Phone: <strong>727-557-6149</strong>
      </p>
    </LSModal>
  )
}

export default ContactModal
