import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ThemeContext } from '../../../context/ThemeProvider'
import actions from '../../../redux/modals/actions'

import styles from './styles.module.scss'
import ContactModal from '../right-sidebar/partials/ContactModal'

const Header = ({
  user,
  setLeftSidebarState,
  leftSidebarState,
  rightSidebar,
}) => {
  const dispatch = useDispatch()
  const modals = useSelector((state) => state.modals)
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  const [search, setSearch] = React.useState('')
  const [headerBG, setHeaderBG] = React.useState(false)
  const [contactModal, setContactModal] = React.useState(false)
  const { theme, toggleTheme } = useContext(ThemeContext)

  useEffect(() => {
    setInnerWidth(window.innerWidth)
    window.addEventListener('resize', () => {
      setInnerWidth(window.innerWidth)
    })

    return () => {
      window.removeEventListener('resize', () => {
        setInnerWidth(window.innerWidth)
      })
    }
  }, [])

  useEffect(() => {
    const positionsCheck = () => {
      if (document && document.documentElement.scrollTop > 10) {
        setHeaderBG(true)
      } else {
        setHeaderBG(false)
      }
    }
    document.addEventListener('scroll', positionsCheck)
    return () => {
      document.removeEventListener('scroll', positionsCheck)
    }
  }, [])

  const handleSearch = (event) => {
    // console.log(event)
  }

  const openSubscriptionModal = () => {
    dispatch({
      type: actions.SUBSCRIPTION,
      payload: {
        subscriptionModal: true,
      },
    })
  }

  const renderSearch = () => {
    return (
      <div className={styles.search} style={{ opacity: 0, display: 'none' }}>
        <input
          type="text"
          placeholder="Quick Search"
          className={styles.input}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleSearch}
        />
        <div className={styles.icon}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.22222 15.4444C12.6587 15.4444 15.4444 12.6587 15.4444 9.22222C15.4444 5.78578 12.6587 3 9.22222 3C5.78578 3 3 5.78578 3 9.22222C3 12.6587 5.78578 15.4444 9.22222 15.4444Z"
              stroke="#3e914a"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.0005 17L13.6172 13.6167"
              stroke="#3e914a"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className={`${styles.advertiserHeader} ${
          !leftSidebarState
            ? styles.advertiserHeaderFull
            : styles.advertiserHeaderCompact
        } ${headerBG ? styles.advertiserHeaderBG : ''} ${
          user.is_admin ? styles.advertiserAdminHeader : ''
        } ${rightSidebar ? styles.advertiserHeaderNoRightSidebar : ''}`}
      >
        <div className={styles.right}>
          <div className={styles.sidebarToggle}>
            <button onClick={() => setLeftSidebarState(true)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z"
                  fill="#3e914a"
                />
                <path
                  opacity="0.5"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 6C3 5.44772 3.44772 5 4 5H16C16.5523 5 17 5.44772 17 6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6Z"
                  fill="#3e914a"
                />
                <path
                  opacity="0.5"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 18C3 17.4477 3.44772 17 4 17H12C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19H4C3.44772 19 3 18.5523 3 18Z"
                  fill="#3e914a"
                />
              </svg>
            </button>
          </div>
          {user && user.is_admin ? (
            <ul className={styles.marketersHeadMenu}>
              <li>
                <Link to="/admin/users">Users</Link>
              </li>
              <li>
                <Link to="/admin/analytics">Analytics</Link>
              </li>
            </ul>
          ) : (
            <ul className={styles.marketersHeadMenu}>
              <li style={{ display: 'none' }}>
                <Link to="/settings/billing">Billing</Link>
              </li>
              <li style={{ display: 'none' }}>
                <Link
                  to="/subscription"
                  onClick={(e) => {
                    e.preventDefault()
                    openSubscriptionModal()
                  }}
                >
                  Subscription
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  onClick={(e) => {
                    e.preventDefault()
                    setContactModal(true)
                  }}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  to="/settings/billing"
                  onClick={(e) => {
                    e.preventDefault()
                    openSubscriptionModal()
                  }}
                >
                  Land IQ
                </Link>
              </li>
            </ul>
          )}
        </div>
        <div className={styles.left}>
          <div className={styles.headerSearch}>{renderSearch()}</div>
          <div className={styles.headerIcons}>
            <div className={styles.iconItem}>
              <button
                onClick={() =>
                  dispatch({
                    type: actions.SET_RIGHT_SIDEBAR_TAB,
                    payload: {
                      rightSidebarActiveTab: 3,
                      rightSidebar: false,
                    },
                  })
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                    stroke="#656567"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.7295 21C13.5537 21.3031 13.3014 21.5547 12.9978 21.7295C12.6941 21.9044 12.3499 21.9965 11.9995 21.9965C11.6492 21.9965 11.3049 21.9044 11.0013 21.7295C10.6977 21.5547 10.4453 21.3031 10.2695 21"
                    stroke="#656567"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className={styles.iconItem}>
              <button
                onClick={() => {
                  if (theme === 'light') {
                    toggleTheme('dark')
                  } else {
                    toggleTheme('light')
                  }
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 12.7207C19.8599 14.237 19.2908 15.682 18.3594 16.8867C17.428 18.0914 16.1728 19.0059 14.7406 19.5232C13.3084 20.0405 11.7585 20.1392 10.2723 19.8078C8.786 19.4764 7.42487 18.7286 6.34813 17.6519C5.27138 16.5751 4.52356 15.214 4.19216 13.7277C3.86076 12.2415 3.9595 10.6916 4.47681 9.25942C4.99413 7.82724 5.90862 6.572 7.1133 5.64059C8.31797 4.70918 9.76299 4.14012 11.2793 4C10.3915 5.20101 9.96435 6.68075 10.0754 8.1701C10.1865 9.65945 10.8284 11.0595 11.8845 12.1155C12.9405 13.1716 14.3405 13.8135 15.8299 13.9246C17.3192 14.0356 18.799 13.6085 20 12.7207Z"
                    stroke="#3e914a"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            {(innerWidth < 1600 && !modals.rightSidebar) ||
            (innerWidth >= 1600 && modals.rightSidebar) ? (
              <>
                <div className={`${styles.iconItem} d-none d-lg-block`}>
                  <button
                    onClick={() =>
                      dispatch({
                        type: actions.SET_RIGHT_SIDEBAR_TAB,
                        payload: {
                          rightSidebarActiveTab: 2,
                          rightSidebar: false,
                        },
                      })
                    }
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 3L19 12L5 21V3Z"
                        stroke="#656567"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className={`${styles.iconItem} d-none d-lg-block`}>
                  <button
                    onClick={() =>
                      dispatch({
                        type: actions.SET_RIGHT_SIDEBAR_TAB,
                        payload: {
                          rightSidebarActiveTab: 4,
                          rightSidebar: false,
                        },
                      })
                    }
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                        stroke="#3e914a"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22 6L12 13L2 6"
                        stroke="#3e914a"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </>
            ) : null}
            <div className={styles.iconItem}>
              <button
                onClick={() => {
                  dispatch({
                    type: actions.RIGHT_SIDEBAR,
                    payload: {
                      rightSidebar: !rightSidebar,
                    },
                  })
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 21V14"
                    stroke="#3e914a"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 10V3"
                    stroke="#3e914a"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 21V12"
                    stroke="#3e914a"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 8V3"
                    stroke="#3e914a"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20 21V16"
                    stroke="#3e914a"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20 12V3"
                    stroke="#3e914a"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 14H7"
                    stroke="#3e914a"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 8H15"
                    stroke="#3e914a"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17 16H23"
                    stroke="#3e914a"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ContactModal
        setVisibility={() => setContactModal(false)}
        visible={contactModal}
      />
    </>
  )
}

export default Header
