const adminSidebarMenu = [
  {
    id: 1,
    path: '/',
    name: 'Dashboard',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4 6C4 4.89543 4.89543 4 6 4H11V14H4V6Z" fill="#3e914a" />
        <path
          opacity="0.5"
          d="M13 10H20V18C20 19.1046 19.1046 20 18 20H13V10Z"
          fill="#3e914a"
        />
        <path
          opacity="0.5"
          d="M13 4H18C19.1046 4 20 4.89543 20 6V8H13V4Z"
          fill="#3e914a"
        />
        <path
          opacity="0.5"
          d="M4 16H11V20H6C4.89543 20 4 19.1046 4 18V16Z"
          fill="#3e914a"
        />
      </svg>
    ),
    isMobileOnly: false,
  },
  {
    id: 2,
    path: '/admin/properties',
    name: 'Properties',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
          fill="#3e914a"
        />
        <path
          opacity="0.3"
          d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
          fill="#3e914a"
        />
      </svg>
    ),
    isMobileOnly: false,
  },
  {
    id: 3,
    path: '/admin/leads',
    name: 'Leads',
    icon: (
      <svg
        width="24"
        height="17"
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.91915 9.60585C8.1367 10.1135 7.90155 10.7014 7.39392 10.9189L3.21216 13.1397C2.48184 13.4527 2 14.1869 2 14.9924L2.00039 14.9952C2.00039 14.9952 2.00184 14.9979 2.00355 14.9997L8 14.9998C8.55229 14.9998 9 15.4475 9 15.9998C9 16.5521 8.55229 16.9998 8 16.9998H2C0.885964 16.9998 0 16.0875 0 14.9924C0 13.3979 0.948539 11.9339 2.42432 11.3014L6.60608 9.08062C7.11371 8.86307 7.70159 9.09822 7.91915 9.60585Z"
          fill="#3e914a"
        ></path>
        <rect x="6" width="8" height="8" rx="4" fill="#3e914a"></rect>
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6402 8.24664C20.0644 8.62285 20.1218 9.29378 19.7682 9.74522L15.4692 15.2343C14.7163 16.1956 13.3502 16.2615 12.5186 15.3767L10.2929 13.0085C9.90237 12.593 9.90237 11.9193 10.2929 11.5037C10.6834 11.0882 11.3166 11.0882 11.7071 11.5037L13.9328 13.8719L18.2318 8.38288C18.5853 7.93144 19.2159 7.87044 19.6402 8.24664Z"
          fill="#3e914a"
        ></path>
      </svg>
    ),
    isMobileOnly: false,
  },
  {
    id: 4,
    path: '/admin/users',
    name: 'Users',
    icon: (
      <svg
        width="24"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM3.465 13.599C4.077 13.113 5.508 12.6 6.759 12.6C6.822 12.6 6.894 12.609 6.966 12.609C7.182 12.033 7.569 11.448 8.136 10.935C7.632 10.845 7.155 10.791 6.759 10.791C5.589 10.791 3.708 11.196 2.502 12.078C2.052 11.142 1.8 10.098 1.8 8.991C1.8 5.022 5.031 1.791 9 1.791C12.969 1.791 16.2 5.022 16.2 8.991C16.2 10.071 15.957 11.097 15.525 12.024C14.625 11.493 13.401 11.241 12.609 11.241C11.241 11.241 8.559 11.97 8.559 13.671V16.173C6.516 16.056 4.698 15.084 3.465 13.599Z"
          fill="#3e914a"
        />
        <rect
          opacity="0.5"
          x="4"
          y="5"
          width="5"
          height="5"
          rx="2.5"
          fill="#3e914a"
        />
        <rect
          opacity="0.5"
          x="11"
          y="7"
          width="3"
          height="3"
          rx="1.5"
          fill="#3e914a"
        />
      </svg>
    ),
    isMobileOnly: false,
    childs: [
      { path: '/admin/users/all', name: 'All' },
      { path: '/admin/users/paid', name: 'Subscribers' },
      { path: '/admin/users/suspended', name: 'Deactivated' },
    ],
  },
]

export default adminSidebarMenu
